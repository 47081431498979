.IyzicoCampaign {
  .campaignTable {
    min-width: 982px;
    height: max-content;

    tr {
      &:hover {
        cursor: pointer;
      }
    }

    th {
      font-size: 13px;
      color: #699bf7;
      text-align: center;
      font-weight: 600;

      span {
        position: relative;
        bottom: 1px;
      }
    }

    td {
      font-size: 15px;
    }

    .inputRow td {
      padding: 0 !important;
    }

    input {
      border: none !important;
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }

  .arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #699bf7;
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #699bf7;
  }
}