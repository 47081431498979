table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
  font-size: 16px;
}

table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}

table.table-striped.table-hover tbody tr:hover {
  background: #f5f5f5;
}

table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}

.bannerekle {
  display: inline-block;
  text-align: right;
}

.bannerekle :hover {
  color: #a0a5b1;
  font-size: 19px;
}

table.table td:last-child {
  width: 130px;
}

table.table td a {
  color: #a0a5b1;
  display: inline-block;
  margin: 0 5px;
}

table.table td a.edit {
  color: #ffc107;
}

table.table td a.delete {
  color: #e34724;
}

table.table td i {
  font-size: 19px;
}

.pagination {
  float: right;
  margin: 0 0 5px;
}

.pagination li a {
  border: none;
  font-size: 95%;
  width: 30px;
  height: 30px;
  color: #999;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 30px !important;
  text-align: center;
  padding: 0;
}

.pagination li a:hover {
  color: #666;
}

.pagination li.active a {
  background: #03a9f4;
}

.pagination li.active a:hover {
  background: #0397d6;
}

.pagination li.disabled i {
  color: #ccc;
}

.pagination li i {
  font-size: 16px;
  padding-top: 6px;
}

.hint-text {
  float: left;
  margin-top: 6px;
  font-size: 95%;
}

.bannerlist {
  margin-top: 25px;
}

.button {
  float: right;
}

.mResult {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.accActions {
  display: flex;
  align-items: center;
  gap: 1rem;

  .fa-edit,
  .fa-trash {
    cursor: pointer;
    font-size: 1rem;
    background-color: #1072ce;
    padding: 1rem;
    border-radius: 0.2rem;
    color: #fff;
  }

  .fa-trash {
    background-color: #fa625e;
  }
}

.acc {
  summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.5rem 1rem;
    font-weight: 600;
    border-bottom: 1px solid #ccc;
  }

  .accContent {
    padding-left: 3rem;
  }
}
