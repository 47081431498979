.Textarea {
  position: relative;
  border: 1px solid #e0e0e2;
  width: 100%;
  height: 10rem;
  padding: 10px 16px;
  font-size: 16px !important;
  color: #616161;
  outline: none;

  &:focus {
    border: 1px solid #818181;
  }
}
