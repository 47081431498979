.text {
  font-family: NexaRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 30px;
  /* identical to box height */

  color: #616161;

  opacity: 0.99;
}
.recommendedProducts {
  background: white;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  padding: 40px 20px;
  min-height: 800px;
  height: 900px;
  width: 100%;
}
.basketTopButtons a.btn:not([href]):not([tabindex]):hover,
.basketTopButtons a.btn:not([href]):not([tabindex]):focus,
.basketTopButtons a.btn:not([href]):not([tabindex]):not(:disabled):not(.disabled):active,
.basketTopButtons .btn:not(.close):not(.mfp-close):hover,
.basketTopButtons .btn:not(.close):not(.mfp-close):focus,
.basketTopButtons .btn:not(.close):not(.mfp-close):not(:disabled):not(.disabled):active {
  background-color: white;
  border-color: #e6e8eb;
  color: #4c5667;
}
.recommendedSwiper {
  margin-bottom: -200px;
  padding-top: -10px;
  background-color: white;
}
.myCard .productCard2 {
  grid-template-columns: 180px 1fr;
  border: 1px solid #cbcbcb;
}
.myCard .tabs .nav-tabs .nav-link.active {
  color: #ee333e;
  border-bottom: 3px solid #ee333e;
}
.myCard .tabs .nav-tabs .nav-link {
  color: #616161;
  font-weight: 600;
  font-size: 16px;
}
.myCard .badge {
  font-size: 12px;
  vertical-align: top;
  padding: 0.3em 0.7em;
  line-height: 1.3;
  margin-left: 10px;
  display: inline;
}
.myCard .productInfoLeft .dot .dotCircle {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  padding-right: 5px;
}
.productInfo {
  width: 100%;
  height: 100%;
}

.myCard .dotColorDesc,
.myCard .guaranteeDesc,
.myCard .storeDesc,
.myCard .approveDesc,
.myCard .truckDesc {
  font-size: 10px;
  margin-left: 5px;
  margin-top: 5px;
}
.myCard .generalStatus .generalStatusBeller > span,
.myCard .generalStatus .generalStatusBeller > i {
  color: #ee333e;
}

.myCard .generalStatus span {
  font-size: 10px;
  margin-left: 5px;
  color: #a1a3a8;
}
.myCard .generalStatus i {
  font-size: 15px;
  color: #a1a3a8;
}
.productStockInfo {
  display: flex;
}
.myCard .productCardName {
  color: #616161;
}
.myCard .productCardName,
.myCard .productCardText {
  font-size: 14px;
}
.myCard .productCardText {
  color: #ec4c4c;
}
.basketTopButtons a {
  text-transform: capitalize !important;
  font-size: 16px !important;
}
.bg-area {
  background: #ffffff;
  border: 1px solid #cbcbcb;
  box-sizing: border-box;
  border-radius: 15px;
}

.bg-gray {
  // width: 214.63px;
  // height: 38.9px;
  background: #e2e2e2;
  // border-radius: 7px;
}

.new-adress-btn {
  background: #ee333e;
  border-radius: 3.08725px;
  height: 2.9rem;
}

.myCardGridMenu {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.link {
  font-size: 20px;
  cursor: pointer;
  padding: 15px 13px;
  display: inline-flex;
  margin-right: 14px;
  border-radius: 9px;
  background: #ffffff;
  color: #616161;

  &:hover {
    color: #ee333e;
  }
}

.active-item {
  color: #ee333e;
}

.rightLink {
  display: flex;
  width: auto;
  height: auto;
}

.products {
  display: inline-flex;
  background-color: #ffffff;
  border-radius: 15px;
  padding: 55px 52px;
  width: 100%;
  margin: 10px auto;

  .image {
    padding-top: 62px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
}
.productInformation {
  padding: 52px 43px;
  font-size: 10px;

  .productName {
    color: #000000;
  }

  .stockAmount {
    padding-top: 29px;
    display: inline-flex;

    .stock {
      padding-top: 6px;
      padding-right: 26px;
    }

    .input {
      display: inline-flex;
    }

    .input input {
      padding-top: 2px 2px;
      width: 21%;
      height: 5%;
    }

    .input i {
      padding-top: 7px;
    }

    .delete {
      padding-top: 7px;
      margin-left: 71px;
    }
  }
}
.productModel .productCode {
  font-size: 9px;
}
.bankOpportunity {
  margin-top: 30px;
}
.mycardProductPrice {
  padding-top: 40px;
  padding-bottom: 50px;
  font-size: 12px;
  text-align: left;
  padding-left: 20px;
  border-left: 1px solid #e0e0e2;
  min-width: 265px;

  .percentDiscount {
    width: 28px;
    height: 17px;
    border-radius: 27px;
    color: #ffffff;
    background-color: #ee333e;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .discount {
    text-align: right;
  }
}
.payment_method {
  background-color: #ffffff;
  border: 1px solid #cbcbcb;
  padding: 55px 52px;
  width: 100%;
  margin: 10px auto;
}
.myCard .form-control,
.myCard input {
  color: #6b6b6b;
  background-color: #e2e2e2;
  border: 1px solid #ced4da;
  border-radius: 7px;
}
.myCard select {
  background-image: url('https://inspirothemes.com/polo/images/dropdown-arrow.png') !important;
}
.address,
.order-note,
.order_details {
  font-size: 16px;
  background-color: #ffffff;
  padding: 0px 20px 10px 20px;
  border: 1px solid #e0e0e2;
  width: 100%;
  margin: 10px auto;
}

.productText {
  position: absolute;
  width: 400px;
  border-radius: 15px;
  padding: 40px 7px;
  background-color: #ffffff;
  font-size: 16px;

  .prices {
    display: flex;
    padding-top: 40px;

    .price {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 60px;
    }
  }

  .offer {
    padding-top: 15px;
  }
}
.recommendedproductStock {
  margin-bottom: 10px;
  color: #00a843;
  width: auto;
  font-size: 17px;
  display: flex;
  line-height: 25px;
  .stockInput {
    display: flex;
    input {
      font-size: 9px;
      width: 25%;
      height: 15%;
      text-align: center;
    }
    i {
      padding-top: 10px;
    }
  }
}
.recommendedProducts {
  background-color: #ffffffaf;

  color: #616161;
  border-radius: 19px;
  padding-top: 59px;
  .recommendedTitle hr {
    color: #e80000;
  }
  .recommendedProductsCard {
    width: 50%;
    height: 100%;
    margin-left: 30px;
    display: flex;
    padding-top: 63px;
  }
}
.mycardProductCardLeft {
  padding-top: 40px;
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.myCardproductCard {
  border-radius: 15px;
  background-color: #ffffff;
  padding-left: 30px;
  display: flex;
  border: 1px solid #e0e0e2;
  div {
    padding-bottom: 6px;
  }
  .productDetail {
    display: flex;
    padding-top: 7px;
    div {
      padding-bottom: 15px;
      padding-left: 7px;
    }

    .productImage {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 118px;
      height: 92;

      img {
        max-width: 100%;
        max-height: 100%;
        display: block;
      }
    }
  }
  .productButton {
    justify-content: center;
    margin-top: auto;
  }
  .productRightIcon {
    padding-bottom: 0px;
    padding-right: 15px;
    .actions {
      position: absolute;
      top: 18px;
      right: 10px;

      .action {
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: 2px solid #dfdfdf;
        width: 34px;
        height: 34px;
        cursor: pointer;
        margin-bottom: 8px;
        transition: border-color 0.3s;

        &:hover {
          border-color: #999;
        }

        svg {
          &.clickAnimation {
            animation: iconAnimation 0.3s;
          }
        }
        @keyframes iconAnimation {
          from {
            transform: scale(1);
          }
          to {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .myCard .productCard2 {
    grid-template-columns: 1fr;
  }
}
.StockNumber {
  font-weight: 500;
  color: #616161;
  font-size: 10px;
}

.Quantity {
  display: flex;
  margin: 0;

  span {
    display: block;
  }

  button {
    display: flex;
    align-items: center;
    color: #ababaf;
    width: 17px;
    height: 26px;
    margin: 0 !important;
    border: none;
    background: none;

    &.plus {
      padding-left: 0;
    }
  }

  input {
    width: 36px;
    height: 24px;
    min-height: 20px !important;
    margin: 0;
    padding: 0 !important;
    text-align: center;
    line-height: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    background-color: #ffffff;
    padding-top: 5px;
  }
}
.discountCode {
  background-color: #ffffff;
  border: 1px solid #ec4c4c;
  color: #ec4c4c;
  &:hover {
    color: #fff;
  }
}
.productDiscount {
  width: 38px;
  height: 17px;
  color: #fff;
  text-align: center;
  background-color: #ec4c4c;
  border-radius: 20px;
}

.TopluAddress {
  display: flex;
  justify-content: flex-end;
  .infoWrapper {
    display: block;
    position: relative;
  }

  .infoMarker {
    font-size: 18px;
    font-weight: 600;
    color: red;
    cursor: pointer;
    padding-right: 1rem;
  }

  .infoMarker:hover + .infoBox {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
  }

  .infoBox {
    display: block;
    position: absolute;
    top: 100%;
    left: -500px;
    visibility: hidden;
    pointer-events: none;
    width: 1200px;
    border: 1px solid #ccc;
    z-index: 2;
    background-color: #fff;
    opacity: 0;
    transition: visibility, opacity, 0.3s;

    @media (max-width: 1040px) {
      width: 60vw;
    }

    &:hover {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }
  }
}
