.Menu {
  width: 100%;
  padding: 1rem 0;
  background-color: #f0f0f0;
  margin: 0;

  nav {
    position: relative;
    display: grid;
    // grid-template-columns: calc(100% - 280px) 150px(Favorite Button) 130px;
    grid-template-columns: calc(100% - 130px) 130px;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 1rem;
    gap: 0.5rem;

    .menuBar {
      position: relative;
      display: flex;
      gap: 0.5rem;
      height: 100%;
    }

    .linkWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .innerLinkWrapper {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      overflow-y: hidden;
      overflow-x: auto;

      display: flex;
      gap: 0.5rem;
      padding: 0 0.5rem;

      width: 100%;
      height: 60px;
      background: #f0f0f0;
      border-radius: 1rem;

      @media (min-width: 1024px) {
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
          background: #888;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      }
    }

    .imglogo {
      height: 45px;
      width: 90;
      margin-right: 20px;
      margin-top: 0px;
    }

    .searchInput {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 20;

      width: 100% !important;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.95);
      outline: none !important;
      border: none !important;
      border-radius: 1rem !important;

      font-size: 17px !important;
      font-weight: 500;

      transition: opacity, visibility, 0.4s;
      opacity: 0;
      visibility: hidden;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      // margin-left: auto;
      z-index: 20;
    }

    .profileImage {
      position: relative;
      display: block;
    }

    .searchButton {
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      cursor: pointer;

      img {
        display: block;
        width: 60%;
        height: 60%;
        margin-top: 10px;
      }
    }

    .profilbutton {
      margin: 0;
      padding: 0;
    }

    .menuButton {
      position: relative;
      width: 32px;
      height: 28px;
      cursor: pointer;

      span {
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #252525;

        &:first-child {
          top: 0;
        }

        &:nth-child(2) {
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:last-child {
          bottom: 0;
        }
      }
    }
  }

  .megaMenu {
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 72px);
    background-color: #f0f0f0;
    padding: 0.5rem 0;
    overflow-y: auto;
    z-index: 1;

    transition: visibility, opacity, 0.4s;
    visibility: hidden;
    opacity: 0;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }

  .menuRow {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));

    position: relative;
    box-sizing: border-box;

    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 1rem;

    // display: table;
  }
}

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px;
  /* gutter size offset */
  width: auto;
  padding: 0 1rem;
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* gutter size */
  background-clip: padding-box;
}

.dataList {
  margin-bottom: 1rem;
  border-radius: 15px;
}
