.p-radio {
  display: flex !important;
  align-items: center;
}

.radio-text {
  display: block;
  height: 100%;
  margin: 0;
  padding-top: 5px;
  padding-left: 1rem;
  font-size: 15px;
  cursor: pointer;
}

.p-radio input[type="radio"]+.p-radio-style:before {
  border: 3px solid rgb(111, 104, 104);
}