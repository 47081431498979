.dealerTable th,
.dealerTable td {
  font-size: 14px !important;
  text-align: center;
  padding: 0.25rem 0.4rem !important;
}

.updateButton {
  min-width: auto;
  padding: 0.3rem 0.5rem;
  height: unset;
  border-radius: 0.25rem;
  font-size: 14px;
}

.tableIcon {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.documentModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-block: 1rem;
}

.documentLink {
  display: block;
  color: red;

  &:hover {
    color: darken(red, 10) !important;
  }
}
