.tabs .nav-tabs .nav-item {
  margin-bottom: -1px;
}
/* .tabs .nav-tabs .nav-link:hover {
  color: #ee333e;
  color: #EE333E!important; 
} */

.tabs .nav-tabs .nav-link.active {
  color: #ee333e;
  border-color: #ee333e;
  /* border-color: #EE333E!important; */
  /* color: #EE333E!important; */
}
