.dealerSpecificPromotional {
  .campaignText {
    font-weight: 600;

    .link,
    b {
      color: #c51b3a;
      font-size: 16px;
    }

    .link {
      padding-left: 6px;

      &:hover {
        color: lighten(#c51b3a, 10);
      }
    }
  }

  .targetTable {
    width: 100%;
    background-color: #fff;
    z-index: 2;

    th {
      color: #c51b3a;
      font-weight: 700;

      &:first-of-type {
        width: 200px;
      }

      &:nth-of-type(2) {
        min-width: 400px;
      }

      &:nth-of-type(3) {
        width: 180px;
      }

      &:last-of-type {
        width: 50px !important;
        text-align: center;
      }
    }
    td {
      vertical-align: middle;
    }
  }

  .tableIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #c51b3a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#c51b3a, 10);
    }
  }

  .progress {
    background-color: #ddd;
    border-radius: 0;
  }

  .progress-bar {
    padding: 0;
  }

  .progressQuantity {
    position: absolute;
    inset: 0;
    margin: auto;
    width: max-content;
    height: max-content;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .progressQuantityMin {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #252525;
  }

  .progressQuantityMax {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #252525;
  }

  .campaignTable {
    min-width: 982px;
    height: max-content;

    tr {
      &:hover {
        cursor: pointer;
      }
    }

    th {
      font-size: 13px;
      color: #c51b3a;
      text-align: center;
      width: 16.66%;
      font-weight: 600;

      span {
        position: relative;
        bottom: 1px;
      }
    }

    td {
      font-size: 15px;
    }

    .inputRow td {
      padding: 0 !important;
    }

    input {
      border: none !important;
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }

  .campaignModalWrapper {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 60%);
    cursor: pointer;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: visibility, opacity, 0.4s;

    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .campaignModal {
    position: absolute;
    inset: 0;
    margin: auto;
    max-width: 900px;
    height: max-content;
    max-height: 80vh;
    background-color: #fff;
    padding: 1rem;
    z-index: 300 !important;
  }

  .arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #c51b3a;
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #c51b3a;
  }
}
