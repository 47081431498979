// $productColorPrimary: #ec4c4c;
// $productColorSecondary: #0fbde0;

.Button {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 130px;
  width: 100%;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #ec4c4c;

  font-size: 16px;
  font-weight: 600;
  color: #fff;

  transition: background-color 0.3s;

  &:hover {
    background-color: darken(#ec4c4c, 5);
  }

  img {
    margin-right: 0.5rem;
    width: 20px;
  }
}

.Rounded {
  border-radius: 10px;
}
