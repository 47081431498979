.sshProcedures .descriptionSSH {
  text-decoration: underline !important;
  color: #dc3545;
}
.sshProcedures .formEl span {
  font-size: 1.5rem;
}
.sshProcedures .heading-text h4 {
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #616161;
}
.border-div {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 30px;
}
.sshProcedures .grid img {
  width: 60px;
  height: 60px;
}
