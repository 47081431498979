$valid-color: red;

.validText {
  color: $valid-color;
  font-size: 10px;
}

.CellWithCommentUpdate {
  position: relative;
}

.CellCommentUpdate {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: green;
  padding: 3px;
  color: green;
  top: 20px;
  left: 20px;
}
.CellCommentUpdateWebinarCalanderAdmin {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: white;
  border: 1px solid red;
  padding: 3px;
  color: red;
  top: 20px;
  left: 20px;
  font-size: 18px;
}
.CellWithCommentUpdate:hover span.CellCommentUpdate,
.CellWithCommentUpdate:hover span.CellCommentUpdateWebinarCalanderAdmin {
  display: block;
}

.CellWithCommentDelete {
  position: relative;
}

.CellCommentDelete {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  padding: 3px;
  color: red;
  top: 20px;
  left: 20px;
}

.CellWithCommentDelete:hover span.CellComment {
  display: block;
}
