.statusInfoMessage {
  font-size: 16px;
}

.readOnlyForm {

  input[readonly],
  input[name='Phone'],
  input[name='MobilePhone'],
  input[name='Fax'] {
    background-color: #e9ecef !important;
  }
}

.sellerApplication {
  .form-group {
    position: relative;
    margin-bottom: 2.5rem !important;
  }
}

.validationError {
  position: absolute;
  top: 100%;
  margin-top: 0.4rem;
  font-size: 1rem;
  color: red;
}

.validationError2 {
  font-size: 1rem;
  color: red;
}

.sellerApplicationForm {
  a {
    transition: color 0.4s ease-in-out;
  }

  .required {
    color: red;
    font-size: 20px;
    top: 0.5rem;
    left: 0.25rem;
    position: relative;
  }

  .infoWrapper {
    display: block;
    position: relative;
  }

  .infoMarker {
    font-size: 18px;
    font-weight: 600;
    color: red;
    cursor: pointer;
    padding-right: 2rem;
  }

  .infoMarker:hover+.infoBox {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
  }

  .infoBox {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    width: 600px;
    border: 1px solid #ccc;
    z-index: 10000;
    background-color: #fff;
    opacity: 0;
    transition: visibility, opacity, 0.3s;

    @media (max-width: 1040px) {
      width: 60vw;
    }

    &:hover {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
    }
  }
}

.radioOptionsContainer {
  display: flex;
  //justify-content: space-between;
  /* Adjust spacing and alignment as needed */
  /* margin-bottom: 10px; */
  /* Optionally add margin to the container */
}

/* Optional: If you want the radio options to be centered vertically */
.form-check {
  display: flex;
  align-items: center;
}