$myReturnsColor: #616161;
$myReturnsBorderColor: #e0e0e2;
$productColorPrimary: #ee333e;

.myReturns {
  background-color: #f5f5f5;

  .box {
    padding: 30px;
    // background-color: #fff;
    // border: 1px solid #f0f0f1;
  }

  .sidebar {
    max-width: 309px;
    min-height: 456px;
    margin-bottom: 1rem;

    .userName {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      position: relative;
      top: 3px;
      color: #ee3439;
      font-size: 18px;
      font-weight: 500;
    }

    .links {
      margin-left: 41px;

      a {
        display: block;
        color: #616161;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 1rem;
      }
    }
  }

  .sidePhotos {
    display: block;

    img {
      margin-bottom: 1rem;
    }
  }

  .search {
    position: relative;

    .icon {
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    input {
      display: block;
      max-width: 285px;
      margin-left: auto;
      background-color: #f0f0f1;
      font-size: 17px;
      padding-right: 40px;

      &::placeholder {
        position: relative;
        top: 2px;
        font-size: 18px;
        color: #ababaf;
      }
    }
  }

  .ReturnsTitle {
    position: relative;
    color: $myReturnsColor;
    font-size: 18px !important;
    font-weight: 500;
    padding-bottom: 1rem;
    padding-left: 1.3rem;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 35px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background-color: #ec4c4c;
      width: 122px;
      height: 3px;
      display: block;
    }
  }

  .myReturnsActions {
    display: flex;
    gap: 1rem;
    margin: 5rem auto;
    max-width: 624px;
    width: 100%;

    @media (max-width: 790px) {
      flex-wrap: wrap;
    }
  }

  .button {
    background-color: #fff;
    color: #ec4c4c;
    font-size: 18px;
    border: 1px solid #ee333e;

    &:hover {
      background-color: #ee333e;
      color: #fff;
    }
  }

  .marketPlace {
    width: 100%;
    padding: 0 15px;
    border: 1px solid #e0e0e2;

    b {
      font-size: 18px;
      font-weight: 500;
      color: #616161;
    }

    .sellerImage {
      width: 65px;
    }

    .marketPlaceActions {
      color: #ee333e;
      font-size: 18px;
      font-weight: 500;
    }

    .text-green {
      color: #30b866;
    }

    .text-red {
      color: #ee333e;
    }

    .item {
      font-weight: 500;
      padding: 2rem 1rem;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    .itemName {
      color: #616161;
      font-size: 18px;
      margin-bottom: 0.5rem;
    }

    .itemValue {
      font-size: 15px;
      color: #939393;
    }
  }

  .results {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 30px;
    font-size: 27px;
    color: #252525;
    text-align: center;

    .title {
      margin-bottom: 15px;
      color: #7a7a7a;
    }

    .message {
      font-weight: 500;
    }
  }
}

@media (max-width: 767px) {
  .myReturns .box {
    padding: 0;
  }
  .myReturns .max-sm-100 {
    max-width: 100% !important;
  }
}
.returnForm {
  .tableIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #c51b3a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#c51b3a, 10);
    }
  }
}

.myReturns {
  .tableIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #c51b3a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#c51b3a, 10);
    }
  }
}
