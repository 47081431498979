.addProductLimitation .leftContainer .listCariBtn.active {
  background: #efeff6;
  border: 1px solid #699bf7;
  color: #699bf7;
}

.addProductLimitation .leftContainer .listCariBtn {
  background: #f6f6f6;
  border: 1px solid #efeff6;
  color: #000;
  font-size: 12px;
  height: 60px;
  justify-content: flex-start;
  padding-left: 1rem;
  width: 100%;
}

.addProductLimitation .cariFilterTextAreaWrap {
  display: none;
  width: unset !important;

  &.active {
    display: block;
  }
}

.addProductLimitation .leftContainer {
  align-items: flex-end;
  display: grid;
  grid-template-columns: 150px 1fr;
  width: 100%;
}
.addProductLimitation .campaignFileInput {
  align-items: center;
  background-color: #699bf7 !important;
  border-radius: 6px;
  display: flex;
  height: 44px;
  margin-right: 1rem;
  outline: 1px solid #fff !important;
  padding-block: unset;
  transition: background-color .3s;
}

.addProductLimitation .campaignExcelBtn {
  align-items: center;
  background-color: transparent;
  border: 1px solid #699bf7;
  border-radius: 6px;
  color: #699bf7;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  height: 44px;
  margin-bottom: 1.5rem;
  padding-inline: 1rem;
  transition: background-color .3s;
  width: -webkit-max-content;
  width: max-content;

  &:hover {
      background-color: #699bf7 !important;
      color: #fff !important;
    }
}