.CellWithComments {
  position: relative;
}

.CellComments {
  display: none;
  position: absolute;
  z-index: 100;
  background-color: white !important;
  padding: 3px;
  color: white !important;
  top: 20px;
  left: 20px;
}

.CellWithComments:hover span.CellComments {
  display: block;
}
