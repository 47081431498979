* {
  font-family: 'Nexa';
}

.countdown .countdown-container .countdown-box span {
  color: #ee333e;
  font-family: 'Nexa';
}

.countdown span {
  padding-top: 4px !important;
}

.countdown.medium .number,
.countdown.circle.medium .number,
.countdown.rectangle.medium .number {
  margin-bottom: 4px !important;
}

.countdown.medium .countdown-box {
  height: 80px;
  width: 70px;
  padding: 13px 4px 0 0;
}

.product-description .product-title {
  font-family: 'Nexa';
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #616161;
  line-height: 25px;
  height: auto !important;
}

.timeDots {
  position: absolute;
  right: 0;
  bottom: 35px;
}

.countdown span:before {
  display: none;
}

.countdown-box:first-child {
  border-right: 1px solid #ee333e !important;
}

.client-logos.header-logos .polo-carousel-item {
  padding: 20px 0px;
}

.carousel.header-logos .polo-carousel-item img {
  width: 100px;
  height: 100px;
}

.carousel-title {
  font-size: 15px;
  font-family: 'Nexa';
  font-weight: 500;
  color: #616161;
  display: block;
  margin-top: 5px;
}

.most-popular-products,
.your-recent-visits,
.similar-your-search,
.the-latest-products {
  background: white;
  box-shadow: 0px 4px 9px rgba(163, 171, 185, 0.24);
  padding: 40px 20px;
}

.most-popular-products-borderless {
  background: white;
  padding: 40px 20px;
}

.similar-your-search,
.the-latest-products {
  min-height: 350px;
  height: 350px;
}

.most-popular-products,
.most-popular-products-borderless,
.your-recent-visits {
  min-height: 420px;
  height: 420px;
}

.section-back-grey {
  box-shadow: 0px 4px 9px rgba(163, 171, 185, 0.24);
}

.section-back-grey .product .product-sale-off {
  padding: 3px 7px 0 7px;
  border-radius: 13px;
  background: #ec4c4c;
  font-size: 13px;
  font-family: 'Nexa';
  margin-top: 0;
  height: auto;
  line-height: 35px;
  position: relative;
  text-shadow: none;
  width: auto;
}

.section-back-grey .product .product-detail .product-price ul {
  height: 42px;
  min-height: 42px;
}

.section-back-grey .product ins,
.section-back-grey .product-bottom-fixed ins {
  font-size: 20px;
  font-family: 'Nexa';
  font-weight: 600;
  margin-right: 5px;
}

.section-back-grey .product-bottom-fixed {
  position: absolute;
  bottom: 45px;
  left: 50px;
  right: 40px;
}

.section-back-grey-title {
  color: #616161;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;
}

.section-back-grey-title:hover {
  background-color: lightgray;
  border-radius: 10px;
}

.section-back-grey-title.active {
  font-weight: 600;
  // text-decoration: underline;
}

.section-back-grey .seperator::before {
  border-bottom: 2px solid #e80000;
  width: 30%;
}

.section-back-grey .seperator::after {
  border-width: 3px;
  width: 70%;
}

.section-back-grey .similar-products {
  color: #8c9197;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  font-family: 'Nexa';
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  font-weight: 400;
}

.header-inner #widget-search-form-sidebar .widget-search-form::placeholder {
  color: #c4c4c4;
}

.header-inner
  #widget-search-form-sidebar
  .input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  margin-left: -20px;
}

@media (min-width: 1024px) {
  // .homeSpecialBanner h3 {
  //   font-size: 30px;
  //   font-weight: 500;
  // }
  .timeDots {
    bottom: 35px;
  }

  .call-to-action {
    position: relative;
    overflow: hidden;
    padding: 30px 50px 30px;
  }
}

.product .product-detail .product-price del {
  font-weight: 400;
  font-size: 14px;
}

.sidebarWrapper {
  display: flex;
  gap: 1rem;

  .sidebar2 {
    width: 260px;

    .companyLogo {
      padding: 28px 0;
      height: 100px;
    }

    nav {
      display: block;
      background-color: #075697;
      height: calc(100% - 120px);
      margin-bottom: 20px;

      a {
        position: relative;
        display: flex;
        align-items: center;
        padding-top: 3px;
        padding-left: 25px;
        padding-right: 2rem;
        // height: 50px;
        border-top-left-radius: 37px;
        border-bottom-left-radius: 37px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-left: 10px;
        color: #fff;

        transition: 0.4s;

        &:hover,
        &.active {
          background-color: #fff;
          color: #075697;

          &::before {
            content: '';
            position: absolute;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 26px;
            background-color: #fff;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            right: 1rem;
            width: 8px;
            height: 8px;
            border-right: 3px solid #075697;
            border-bottom: 3px solid #075697;
          }
        }
      }

      .hasSub {
        position: relative;

        &:hover > .subMenu {
          opacity: 1;
          pointer-events: all;
        }
      }

      .subMenu {
        position: absolute;
        top: 0;
        left: 100%;
        width: max-content;
        background-color: #fff;
        box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.2);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;

        a {
          align-items: unset;
          padding-top: unset;
          padding-left: unset;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
          margin-left: 0;
          height: auto;

          width: 100%;
          padding: 1.5rem 2rem;
          position: relative;
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: #252525;

          &:hover,
          &.active {
            color: red;

            &::before {
              content: unset;
            }

            &::after {
              content: unset;
            }
          }
        }

        .hasSub > a:hover::after {
          content: '';
          position: absolute;
          top: 47%;
          transform: translateY(-50%) rotate(-45deg);
          right: 6px;
          width: 8px;
          height: 8px;
          border-right: 3px solid #252525;
          border-bottom: 3px solid #252525;
        }
      }
    }
  }

  main {
    width: calc(100% - 260px);

    @media (max-width: 992px) {
      width: calc(100%);
    }
  }
}

@media (max-width: 1200px) {
  .card-mobile-resize {
    margin-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  // .homeSpecialBanner h3 {
  //   font-size: 20px;
  // }
  .timeDots {
    bottom: 21px;
  }

  .countdown .countdown-box {
    height: 50px !important;
    padding: 8px 0px 0 0 !important;
  }
}

@media (max-width: 800px) {
  .section-back-grey.mobile-resize {
    padding: 30px 10px !important;
  }

  .section-back-grey .card-mobile-resize {
    margin-bottom: 20px;
  }
}

.last-column {
  flex: 25;
  max-width: 100%;
}

.last-column-campaign {
  max-width: 100%;
}
