.kutu1 {
  background-color: white;
  text-align: center;
  width: 250px;
  font-size: 22px;
  border: 1px solid gray;
}

.kutu2 {
  background-color: white;
  text-align: center;
  width: 330px;
  font-size: 22px;
  border: 1px solid gray;
}

.titlebanaozel {
  color: black;
  font-size: 20px;
  font-weight: 400;
}

.iletisimbilgi {
  font-size: 20px;
  text-align: center;
}

.profil {
  margin-top: -115px;
}

.special-home .form-control.special-home-button-left {
  border-radius: 21.5px 21.5px 0 0 !important;
}

.special-home .form-control.special-home-button-left,
.special-home .form-control.special-home-button-right {
  background-color: rgba(15, 189, 224, 0.2);
  color: #616161;
  font-weight: 500;
}

.special-home .form-control.special-home-button-right {
  //border-radius: 21.5px !important;
  border-radius: 21.5px 21.5px 0 0 !important;
}

.special-home .table-title {
  margin: 20px 0;
}

.special-home .table-title,
.special-home .board-title {
  font-size: 18px;
  font-weight: 500;
  color: #616161;
}

.special-home .table-bordered {
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.14);
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e0e0e2;
}

.special-home .table-bordered td {
  border-top: none;
  border-bottom: 1px solid #e0e0e2 !important;
}

.special-valid .validationError {
  position: static;
}

.special-home .board-subtitle {
  color: #a1a3a8;
  font-weight: 400;
  display: block;
  font-size: 1rem;
  height: 25px;
}

.special-home .board-title {
  margin: 20px 0 0 0;
}

.sidebar-right .card {
  padding-left: 15px;
  padding-right: 15px;
}

.sidebar-right .table-responsive.table-height {
  overflow-y: scroll;
  height: 268px;
}

.sidebar-right .table.nobottommargin thead tr {
  background: #f5f5f5;
  border: 1px solid #e0e0e2;
}

.sidebar-right .table.nobottommargin thead tr th,
.sidebar-right .table.nobottommargin tbody tr td {
  color: #616161;
  font-weight: 500;
}

.pageTitle {
  position: relative;
  color: #616161;
  font-size: 18px !important;
  font-weight: 500;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 35px;

  span {
    position: relative;
    display: inline-block;
    padding: 0 0 15px 0;
  }

  span::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    background-color: #ec4c4c;
    width: 100%;
    height: 3px;
    display: block;
  }
}

@media (max-width: 767px) {
  .overflow-x-scroll {
    overflow-x: scroll;
  }

  .userupdate .dataTables_wrapper .dataTables_length,
  .userupdate .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }

  .userupdate #datatable_wrapper .dataTables_length select {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .userupdate #datatb_filter input {
    width: 100%;
  }
}

.currency-tab {
  cursor: pointer;
  margin-right: 2rem !important;
  padding: 4px 8px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;

  &:hover {
    background-color: #182747;

    span {
      color: #fff !important;
    }
  }

  span:hover {
    color: #fff !important;
  }

  .invoice-input {
    // input {
    padding: 4px 6px !important;
    // }
  }
}

.company--tab-menu {
  border: 0;
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: 2rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
  }

  li {
    margin: 0 !important;
  }
}

.currency--tab-menu {
  border: 0;
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: 2rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
  }

  li {
    margin: 0 !important;
  }
}