.Mc_Hedef,
.Mc_Kampanya,
.Mc_Akademi,
.NetChannel,
.NetChannel_Dash,
.NetChannel_Edu,
.Mc_Proje {
  max-height: 180px;
  height: auto;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.Mc_Hedef {
  box-shadow: 0 4px 8px 0 #ff6600;
}

.Mc_Kampanya {
  box-shadow: 0 4px 8px 0 #0edf2a;
}

.Mc_Akademi {
  box-shadow: 0 4px 8px 0 #06def1;
}

.Mc_Proje {
  box-shadow: 0 4px 8px 0 #dcc009;
}

.Mc_Hedef-hover {
  background-color: antiquewhite;
}

.Mc_Kampanya-hover {
  background-color: #bcf8c5;
}

.Mc_Akademi-hover {
  background-color: #beeef1
}

.Mc_Proje-hover {
  background-color: #f8f2d3;
}

.NetChannel {
  box-shadow: 0 4px 8px 0 black;
}

.NetChannel-hover {
  background-color: gray;
}

.NetChannel_Dash {
  box-shadow: 0 4px 8px 0 black;
}

.NetChannel_Dash-hover {
  background-color: gray;
}

.NetChannel_Edu {
  box-shadow: 0 4px 8px 0 black;
}

.NetChannel_Edu-hover {
  background-color: gray;
}

.Mc_Hedef-hover,
.NetChannel-hover,
.NetChannel_Dash-hover,
.NetChannel_Edu-hover,
.Mc_Kampanya-hover,
.Mc_Akademi-hover,
.Mc_Proje-hover {
  display: none;
  /* Initially hide the hover image */
}

.image-hover:hover .Mc_Hedef,
.image-hover:hover .Mc_Kampanya,
.image-hover:hover .NetChannel,
.image-hover:hover .NetChannel_Dash,
.image-hover:hover .NetChannel_Edu,
.image-hover:hover .Mc_Akademi,
.image-hover:hover .Mc_Proje {
  display: none;
  /* Hide the original image on hover */
}

.image-hover:hover .Mc_Hedef-hover,
.image-hover:hover .Mc_Kampanya-hover,
.image-hover:hover .NetChannel-hover,
.image-hover:hover .NetChannel_Dash-hover,
.image-hover:hover .NetChannel_Edu-hover,
.image-hover:hover .Mc_Akademi-hover,
.image-hover:hover .Mc_Proje-hover {
  display: inline-block;
  /* Show the hover image on hover */
}