.textarea {
  height: 100px;
}

.textareabayikod {
  height: 140px;
}

.whiteDealer {
  .leftContainer {
    display: grid;
    grid-template-columns: 150px 1fr;
    // align-items: flex-end;
    width: 100%;

    .listCariBtn {
      background: #f6f6f6;
      color: #000;
      width: 100%;
      border: 1px solid #efeff6;
      justify-content: flex-start;
      padding-left: 1rem;
      font-size: 12px;
      height: 60px;

      &.active {
        background: #efeff6;
        border: 1px solid #699bf7;
        color: #699bf7;
      }
    }

    .cariFilterTextAreaWrap {
      display: none;
      width: unset !important;

      &.active {
        display: block;
      }
    }

    .cariFilterList {
      width: 100%;
      height: 133px;
      border: 1px solid #ccc;
    }
  }

  .campaignExcelBtn {
    width: max-content;
    height: 44px;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #699bf7;
    border-radius: 6px;
    border: 1px solid #699bf7;
    font-size: 15px;
    font-weight: 600;
    padding-inline: 1rem;
    margin-bottom: 1.5rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #699bf7 !important;
      color: #fff !important;
    }
  }

  .campaignFileInput {
    background-color: #699bf7 !important;
    outline: 1px solid #fff !important;
    border-radius: 6px;
    width: max-content;
    height: 44px;
    display: flex;
    align-items: center;
    padding-block: unset;
    transition: background-color 0.3s;
    margin-right: 1rem;

    &:hover {
      background-color: darken(#699bf7, 5) !important;
    }
  }
}