.Input {
  border: 1px solid hsl(0, 0%, 80%);
  width: 100%;
  height: 47px;
  padding: 10px 16px;
  font-size: 16px !important;
  color: #616161;
  outline: none;
  border-radius: 0;

  &:focus {
    border: 1px solid #818181;
  }
}

/* Chrome, Safari, Edge, Opera */
.hideArrows::-webkit-outer-spin-button,
.hideArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideArrows[type='number'] {
  -moz-appearance: textfield;
}
