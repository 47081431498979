$mobil: 992px;

.mainInfo {
  // border: 2px solid red;
  box-sizing: border-box;
  padding-left: 3rem;
}

.icon {
  width: 40px;
  height: 40px;
  color: #777;
  margin: 0.4rem 0;

  i {
    font-size: 32px;
  }
}

.mainImage {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.autoWidth {
  min-width: 200px;
  max-width: max-content;
  display: flex !important;
  justify-content: space-between;
}

.imageBorder {
  width: 7rem;
  height: 7rem;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}

.colorShape {
  background: #c4c4c4;
  border: 3px solid #0fbde0;
  box-sizing: border-box;
  width: 49px;
  height: 49px;
  border-radius: 50%;
}

.features > p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;

  color: #555a6c;
}

.tracking h3 {
  font-weight: 500;
  color: #ee333e;
}

.borderRight {
  border-right: 1px solid #c4c4c4;

  &:last-child {
    border: none;
  }
}

.redBanner {
  // background: url("/images/products/redBanner.jpg");
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  height: 112px;
  display: grid;
  place-items: center;
  font-weight: 500;
  font-size: 28px;
  color: white;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

// HEADER RIGHT

.headerRight {
  .headerRightIcons {
    display: block;
  }
}

@media (max-width: 992px) {
  .indicator {
    display: none !important;
  }

  .mobilTitle {
    display: block !important;
  }

  .mobilDesc {
    display: none !important;
  }

  .mobilRelatedProducts {
    display: none !important;
  }
}

// colorCircle

.colorCircle {
  // background: #000000;
  border: 1px solid #0fbde0;
  box-sizing: border-box;
  transform: rotate(-90deg);
  border-radius: 50%;

  width: 1.6rem;
  height: 1.6rem;
}

.indicator {
  position: absolute;
  display: grid;
  place-items: center;
  width: auto;
  height: 30px;
  padding: 3px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;

  z-index: 1;
}

.indicator.topLeft {
  left: 0;
  top: 0;
  background-color: #0fbde0;
  border-bottom-right-radius: 13px;
}

.indicator.topLeftPrice {
  left: 0;
  top: 0;
  background-color: #e5e5e5;
  border-bottom-right-radius: 13px !important;
}

select.customSelectBox {
  width: 45px;
  color: #616161 !important;
  background-size: 15px;
  background-image: url('../../../../../public/images/dropdown-arrow.png') !important;
}

.indicator.bottomLeftBank {
  left: 0;
  bottom: -2.5rem;
  background-color: #dfdfdf !important;
  border-bottom-right-radius: 13px;
  text-align: center;
}

.accordion {
  // display: block;
  padding: 0 0.7rem;
  height: fit-content;
  margin-left: -15px;
}

.dropdownBank {
  position: relative;
  margin-left: -1.9rem;

  .accTitle {
    width: 18rem;
    background-color: #dfdfdf !important;
    border-bottom-right-radius: 13px;
    text-align: center;
    cursor: pointer;
  }

  div {
    p {
      margin-top: 1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }

  .dropdownContent {
    width: 100%;
  }
}

.otherSellers {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-bottom: 1rem;

  .otherSeller {
    width: 10% !important;
    // min-width: 7rem !important;
    height: 3rem;
    background-color: gainsboro;
    margin-top: 0.5rem;
  }
}

.content {
  display: none;
  background-color: #fff;
}

.accOpen {
  display: block;
  width: 100%;
}

.accClose {
  display: none !important;
}

.indicator.topLeft2 {
  left: 0;
  top: 36px;
  background-color: #fac74b;
  border-top-right-radius: 13px;
  background: '#DFDFDF';
}

.indicator.bottomLeft {
  left: 0;
  bottom: 0;
  background-color: #fac74b;
  border-top-right-radius: 13px;
}

.indicator.topRight {
  top: 0;
  right: 0;
  background-color: #ec4c4c;
  border-bottom-left-radius: 13px;
}

.indicator.topRight2 {
  top: 36px;
  right: 0;
  background-color: #00a843;
  border-top-left-radius: 13px;
  padding-top: 3px;
}

.indicator.bottomRight {
  bottom: 0;
  right: 0;
  background-color: #00a843;
  border-top-left-radius: 13px;
  padding-top: 3px;
}

.priceArea {
  position: relative;
  box-shadow: 0px 4px 32px -2px rgba(0, 0, 0, 0.15);
}

.brandLogo {
  width: max-content;
  height: max-content;

  position: absolute;
  left: 50%;
  top: -2rem;
  transform: translate(-50%);
}

.priceLeftBtn {
  width: 25%;
  position: absolute;
  bottom: -2rem;
  right: -0.7rem;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .priceLeftBtn {
    z-index: 1;

    width: 1%;
    right: 10rem;
    // position: relative;
  }

  .dropdownBank {
    .accTitle {
      width: 10rem;
      cursor: pointer;
    }
  }

  .quantityToBasket {
    // width: 50%;
    min-width: 5rem;
    z-index: 1;
  }
}

.quantityToBasket {
  z-index: 1;
  width: 15%;
  padding: 0;

  position: absolute;
  cursor: auto;
  bottom: -2.2rem;
  right: 13rem;
}

// actions
.descTitle {
  position: relative;
}

.actions {
  position: absolute;
  top: 40px;
  right: 15px;

  .action {
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-bottom: 4px;
    transition: border-color 0.3s;
    overflow: hidden;

    &:hover {
      border-color: #999;
    }

    img {
      max-width: 85%;
      max-height: 85%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      &.clickAnimation {
        animation: iconAnimation 0.3s;
      }
    }

    @keyframes iconAnimation {
      from {
        transform: scale(1);
      }

      to {
        transform: scale(1.3);
      }
    }
  }
}

// PRİCE AREA

.priceArea {
  background-color: #ffffff;

  div {
    display: inline-block;
  }
}

// FEEDBACK

.commentList div {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 0.3rem;

  p {
    margin-top: 0.5rem;
    margin-left: 0.8rem;
    font-size: 18px;
    line-height: 36px;

    color: #616161;
  }
}

.inputArea {
  .inputItem {
    display: flex;
    align-items: center;

    Input {
      width: 25%;
      border-radius: 5px;
    }

    p {
      font-size: 18px;
      margin-right: 1rem;
      margin-top: 1rem;
    }
  }
}

textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  height: 14rem;
  resize: none;
}

.seperator {
  border-bottom: 2px solid #e80000;
  width: 25%;
}

.seperatorDark {
  border-bottom: 1px solid #a09f9f;
  width: 90%;
  margin-bottom: 1rem;
  // margin-left: 2rem;
}

.ProductLine {
  width: 143px;
  height: 3px;
  background-color: #ee333e;
  margin-bottom: 58px;
}
