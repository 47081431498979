.sss .heading-text h4 {
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #616161;
}

.sss .ac-title i {
  color: #dc3545;
}
