.faultTracking .heading-text h4 {
  font-size: 1.9rem;
  font-weight: 500;
  margin-bottom: 0;
  color: #616161;
}

.faultTracking form .formEl {
  max-width: 100% !important;
}
