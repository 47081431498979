.productCardGrid {
  display: grid;
}

.productCard {
  position: relative;
  background-color: #fff;
  box-shadow: 1px 0 0 0 #dfdfdf, 0 1px 0 0 #dfdfdf, 1px 1px 0 0 #dfdfdf, 1px 0 0 0 #dfdfdf inset,
    0 1px 0 0 #dfdfdf inset;

  &:nth-child(even) {
    .check-box:checked {
      background-color: $productColorPrimary !important;
      border-color: $productColorPrimary;
    }
  }

  .check-box {
    position: relative;
    height: 21px !important;
    width: 21px !important;
    border-radius: 2px !important;
    z-index: 2;
    margin: 0;
    cursor: pointer;
  }

  .check-box:checked {
    background-color: $productColorSecondary !important;
    border-color: $productColorSecondary;
  }
}

.indicator {
  position: absolute;
  display: grid;
  place-items: center;
  width: 42px;
  height: 30px;

  font-size: 12px;
  font-weight: 600;
  color: #fff;

  z-index: 1;
}

.indicator.topLeft {
  left: 0;
  top: 0;
  background-color: #0fbde0;
  border-bottom-right-radius: 13px;
}

.indicator.topLeft2 {
  left: 0;
  top: 36px;
  background-color: #fac74b;
  border-top-right-radius: 13px;
}

.hpeWrapper {
  position: absolute;
  top: 146px;
  left: 1rem;
  z-index: 2;
  width: 70px;

  img {
    width: 100%;
  }
}

.iQuote {
  margin-top: 0.5rem;
  display: grid;
  place-items: center;
  padding-inline: 0.5rem;
  border: 1px solid #1cb488;
  color: #1cb488;
  font-weight: 600;
  cursor: pointer;
  transition: background-color, color, 0.3s;

  &:hover {
    background-color: #1cb488;
    color: #fff;
  }
}

.indicator.bottomLeft {
  left: 0;
  bottom: 0;
  background-color: #fac74b;
  border-top-right-radius: 13px;
}

.indicator.topRight {
  top: 0;
  right: 0;
  background-color: #ec4c4c;
  border-bottom-left-radius: 13px;
}

.indicator.topRight2 {
  top: 36px;
  right: 0;
  background-color: #00a843;
  border-top-left-radius: 13px;
  padding-top: 3px;
}

.indicator.bottomRight {
  bottom: 0;
  right: 0;
  background-color: #00a843;
  border-top-left-radius: 13px;
  padding-top: 3px;
}

.productImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 220px;
  user-select: none;

  img {
    max-width: 100%;
    max-height: 200px;
    display: block;
  }

  .prev,
  .next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 30px;
    height: 100%;
    z-index: 10;
    cursor: pointer;

    i {
      position: absolute;
      top: 50%;
      margin: auto;
      transform: translateY(-50%);
      font-size: 30px;
      transition: transform 0.4s;
      color: grey;
    }

    &:hover {
      i {
        transform: scale(1.3) translateY(-50%);
      }
    }
  }

  .prev {
    left: 0;

    &:hover {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 4%), transparent);
    }
  }

  .next {
    right: 0;

    &:hover {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 4%), transparent);
    }
  }
}

.productName,
.productCode,
.productModel {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
}

.productCode {
  font-size: 12px;
  color: #616161;
}

.productRoadCondition {
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
}

.productPriceTitle,
.productPrice {
  font-size: 14px;
  color: #a1a3a8;
  font-weight: 500;
}

.productPrice {
  color: #ababaf;
}

.productSpecialPrice {
  font-size: 20px;
  font-weight: 500;
  color: #ec4c4c;
}

.productDiscountIndicator {
  position: relative;
  bottom: 5px;
  width: 37px;
  height: 20px;
  line-height: 23px;
  background-color: $productColorPrimary;
  border-radius: 23px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-left: 3px;
}

.available {
  font-size: 13px;
  font-weight: 500;
  color: #00a843;
  margin-bottom: 5px;
}

.notAvailable {
  font-size: 13px;
  font-weight: 500;
  color: #a80600;
  margin-bottom: 5px;
}

.guarantee {
  display: flex;
  gap: 5px;
  font-weight: 500;
  color: #a1a3a8;
  font-size: 12px;

  img {
    position: relative;
    bottom: 4px;
    width: 22px;
  }
}

.productStock {
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;

  .number {
    display: flex;
    align-items: center;
    color: #a1a3a8;
    margin-right: 5px;
  }

  .quantity {
    margin: 0;
    margin-left: auto;

    span {
      display: block;
    }

    button {
      display: flex;
      align-items: center;
      color: #ababaf;
      width: 17px;
      height: 26px;
      margin: 0 !important;
      border: none;
      background: none;

      &.plus {
        padding-left: 0;
      }
    }

    input {
      width: 26px;
      height: 26px;
      margin: 0;
      padding: 0 !important;
      text-align: center;
      min-height: 26px;
      border: 1px solid #c4c4c4;
      border-radius: 3px;
    }
  }
}

.actions {
  position: absolute;
  top: 70px;
  right: 3px;

  .action {
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 2px solid #dfdfdf;
    width: 32px;
    height: 32px;
    cursor: pointer;
    margin-bottom: 4px;
    transition: border-color 0.3s;
    overflow: hidden;
    justify-self: center;

    &:hover {
      border-color: #999;
    }

    img {
      max-width: 85%;
      max-height: 85%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      &.clickAnimation {
        animation: iconAnimation 0.3s;
      }
    }

    @keyframes iconAnimation {
      from {
        transform: scale(1);
      }

      to {
        transform: scale(1.3);
      }
    }
  }
}

.seller {
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a3a8;
}

.productFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid #e0e0e2;
  padding-top: 21px;

  font-size: 12px;
  font-weight: 500;
  color: #a1a3a8;

  .sellerImage {
    display: inline-block;
    padding: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 27px;
    margin-left: 9px;
  }

  input {
    margin-right: 9px !important;
  }

  .compare {
    display: flex;
    align-items: center;
  }
}

.productButton {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 130px;
  height: 44px;
  cursor: pointer;

  font-size: 14px;
  font-weight: 600;
  color: #fff;

  transition: background-color 0.3s;
}