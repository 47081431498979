section.fixed {
  position: relative;
  /* border: 1px solid #000; */
  padding-top: 37px;
  background: #6c757d;
  padding-bottom: 2px;
}

.fixed .wrap {
  overflow-y: auto;
  max-height: 250px;
}

.fixed table {
  border-spacing: 0;
  width: 100%;
}

.fixed td + td {
  /* border-left: 1px solid #000; */
}

.fixed td,
.fixed th {
  background: #f3fbfc;
  padding: 1px 1px;
}

.fixed th {
  height: 0;
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: transparent;
  border: none;
  white-space: nowrap;
}

.fixed th div {
  position: absolute;
  color: #fff;
  padding: 9px 5px;
  top: 0;
  margin-left: -5px;
  line-height: normal;
  border-left: 1px solid #fff;
  font-weight: 500;
}

.fixed th:first-child div {
  border: none;
}
