.Link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  padding: 0 10px;
  max-width: 175px;
  width: 100%;
  height: 48px;

  background-color: #fff;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: #252525 !important;
  transition: background-color, color, 0.2s;

  .icon {
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    img {
      width: 100%;
    }
  }

  span {
    margin-left: 0.5rem;
  }

  &:hover {
    color: #fff !important;
    background-color: #444;
  }
}
