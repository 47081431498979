.surveyItem {
  background: #f0f0f1;
  margin-bottom: 1rem;
  padding: 1rem;
}

.surveyAnsItem {
  display: flex;
  align-items: center;
}
