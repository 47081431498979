.first-products-banner {
  background: white;
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.24);
  padding: 30px 20px;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}

.first-products-banner .product .product-sale-off {
  border-radius: 13px;
  font-size: 13px;
  background: #ec4c4c;
  font-family: 'Nexa';
  height: auto;
  padding: 3px 7px 0 7px;
  line-height: 35px;
  position: relative;
  text-shadow: none;
  width: auto;
}

.netex_borderBottom::before {
  border-bottom: 2px solid #0055c9 !important;
}

.index_borderBottom::before {
  border-bottom: 2px solid #da291c !important;
}

.despec_borderBottom::before {
  border-bottom: 2px solid #298fc2 !important;
}

.datagate_borderBottom::before {
  border-bottom: 2px solid #00afd7 !important;
}

.default_borderBottom::before {
  border-bottom: 2px solid #dc3545 !important;
}

.red-banner-title {
  font-size: 35px;
  margin-right: 15px;
  font-weight: 500;
  height: 115px;
  margin-top: 25px;
}

.see-more a .btn {
  border: 2px solid #ffffff !important;
  text-transform: capitalize !important;
  font-size: 18px !important;
  border-radius: 0px !important;
  padding: 16px 20px;
  font-weight: 500 !important;
  background-color: transparent !important;
}

.see-more i {
  opacity: 0.5;
  margin-left: 5px;
}

.first-products-banner .product .product-product-detail ins {
  font-size: 20px;
  font-family: 'Nexa';
  font-weight: 600;
  margin-right: 5px;
}

.first-products-banner .p-progress-bar {
  background-color: #ec4c4c;
}

.first-products-banner .p-progress-bar-container.extra-small {
  height: 4px;
}

.first-products-banner .p-progress-bar-container {
  background-color: #c4c4c4;
}

.first-products-banner .p-progress-bar-container.extra-small .p-progress-bar .progress-type,
.first-products-banner .p-progress-bar-container.extra-small .p-progress-bar .progress-number {
  display: none !important;
}

.first-products-banner .product-bottom-desc {
  font-family: 'Nexa';
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #646464;
}

.first-products-banner .product-bottom-desc span:first-child {
  color: #ec4c4c;
}

@media (max-width: 800px) {
  .products-mobile-resize:not(:last-child) {
    margin-bottom: 50px;
  }
}