.AnnouncementList {
  .title {
    font-size: 1.5vw;
  }
}
.wrapper_class {
  padding: 1rem;
  border: 1px solid #ccc;
  size: 35px;
}
.editor_class {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar_class {
  border: 1px solid #ccc;
  width: 100%;
  height: auto;
  font-size: 18px;
}

.labelText {
  color: #777777 !important;
  font-weight: 400 !important;
  font-size: 20px;
  text-align: center;
}
