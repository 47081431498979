.top-brands-item {
  padding: 40px;
  text-align: center;
  height: 172px;
  min-height: 172px;
  display: flex;
  align-items: center;
}

.netex_buttonColor {
  color: #0055c9;
  border: 1px solid #0055c9;
}

.index_buttonColor {
  color: #da291c;
  border: 1px solid #da291c;
}

.despec_buttonColor {
  color: #298fc2;
  border: 1px solid #298fc2;
}

.datagate_buttonColor {
  color: #00afd7;
  border: 1px solid #00afd7;
}

.default_buttonColor {
  color: #dc3545;
  border: 1px solid #dc3545;
}

.default_buttonColor:hover {
  background-color: #dc3545 !important;
  color: #fff;
}

.index_buttonColor:hover {
  background-color: #da291c !important;
  color: #fff;
}

.datagate_buttonColor:hover {
  background-color: #00afd7 !important;
  color: #fff;
}

.despec_buttonColor:hover {
  background-color: #298fc2 !important;
  color: #fff;
}

.netex_buttonColor:hover {
  background-color: #0055c9 !important;
  color: #fff;
}

@media (max-width: 800px) {
  .section-back-grey .top-brands-item {
    height: auto;
    min-height: auto;
    margin-bottom: 20px;
  }

  .section-back-grey .top-brands-item img {
    width: 100px;
  }

  .brand-mobile-resize:not(:last-child) {
    margin-bottom: 20px;
  }
}