.payment-tab {
  cursor: pointer;
  margin-right: 2rem !important;
  padding: 4px 8px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;

  &:hover {
    background-color: #182747;

    span {
      color: #fff !important;
    }
  }

  span:hover {
    color: #fff !important;
  }

  .invoice-input {
    // input {
    padding: 4px 6px !important;
    // }
  }
}

.active-payment-tab {
  background-color: #182747;
  color: #fff;

  &:hover {
    span {
      color: #fff !important;
    }
  }

  span:hover {
    color: #fff !important;
  }
}

.bankLogoRow {
  border: 1px solid #e0e0e2;
  margin-top: 1em;

  .bankLogoCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    .banksImage {
      width: 250px;
      height: 100px;
    }

    .subtitleInstallment {
      border: 0.5px solid #ababaf;
      background-color: #ababaf;
      height: 0px;
      width: 100%;
      margin: 10px 0px;
    }

    .descriptionInstallment {
      display: flex;
      justify-content: start;
      width: 100%;

      div {
        font-size: 15px;
        color: #616161;
        width: 120px;
        text-align: center;
      }
    }

    .installmentContainer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        align-items: center;
        width: 150px;
        margin-left: 40px;
      }

      p {
        display: flex;
        align-items: center;
        font-size: 15px;
        margin-top: 15px;
        margin-left: 5px;
        color: #616161;
        width: 150px;
        justify-content: right;
      }

      .totalPrice {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-left: 50px;
      }
    }

    .installmentContainer2 {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      div {
        display: flex;
        align-items: center;
        width: 150px;
        margin-left: 40px;
      }

      p {
        display: flex;
        align-items: center;
        margin-top: -17px;
        font-size: 13px;
        margin-left: 5px;
        color: red;
        width: 150px;
        justify-content: right;
      }

      .totalPrice {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-left: 50px;
      }
    }
  }
}

tbody .p-radio {
  display: block !important;
}

@media (max-width: 767px) {
  .rccs {
    width: 100%;
  }

  .rccs__card {
    width: 100%;
  }

  #mainMenu {
    overflow: visible !important;
  }

  #mainMenu nav {
    opacity: 1 !important;
  }

  #mainMenu nav > ul > li {
    padding: 0.5rem 1rem !important;
  }

  .pb-sm-150 {
    padding-bottom: 150px !important;
  }
}

.img-star {
  @media (min-width: 768px) {
    width: 5%;
  }

  @media (max-width: 767px) {
    width: 20%;
  }
}

.img-p {
  @media (min-width: 768px) {
    width: 95%;
  }

  @media (max-width: 767px) {
    width: 80%;
  }
}

.fixed th div {
  margin-left: 0 !important;
}

table.table tr td {
  vertical-align: middle;
}

/* Define a CSS class for the bordered table */
.bordered-table {
  border-collapse: collapse;
  width: 100%;
}

.bordered-table tr {
  border-top: 2px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.bordered-table thead tr {
  border: none;
}

.bordered-table th {
  background-color: #f2f2f2;
  color: #333;
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

.bordered-table td {
  padding: 10px;
}

/* CSS classes for positive and negative numbers */
.positive-number {
  color: green;
}

.negative-number {
  color: red;
}

.zero-number {
  color: black;
}

.button-link {
  display: inline-block;
  background-color: rgb(239 246 245);
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.button-link:hover {
  background-color: rgb(204 215 214);
}

#order ul {
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;
}

#order body {
  font-family: 'Inter', sans-serif;
  margin: 0;
}

#order table {
  width: 100%;
  border-collapse: collapse;
}

#order table,
#order table th,
#order table td {
  border: 1px solid silver;
}

#order table th {
  text-align: left;
  padding: 8px;
}

#order table td {
  text-align: right;
  padding: 8px;
}

#order h1,
#order h4,
#order p {
  margin: 0;
}

#order .container {
  padding: 20px 0;
  width: 1000px;
  max-width: 90%;
  margin: 0 auto;
}

#order .inv-title {
  padding: 10px;
  border: 1px solid silver;
  text-align: center;
  margin-bottom: 10px;
}

#order .inv-logo {
  width: 150px;
  display: block;
  margin: 0 auto;
}

/* header */
#order .inv-header {
  display: flex;
  margin-bottom: 20px;
}

#order .inv-header > :nth-child(1) {
  flex: 2;
}

#order .inv-header > :nth-child(2) {
  flex: 1;
}

#order .inv-header h2 {
  font-size: 20px;
  margin: 0 0 0.3rem 0;
}

#order .inv-header ul li {
  font-size: 15px;
  padding: 3px 0;
}

/* body */
#order .inv-body table th,
#order .inv-body table td {
  text-align: left;
}

#order .inv-body {
  margin-bottom: 30px;
}

/* footer */
#order .inv-footer {
  display: flex;
  flex-direction: row;
}

#order .inv-footer > :nth-child(1) {
  flex: 2;
}

#order .inv-footer > :nth-child(2) {
  flex: 1;
}

.hasTooltip {
  position: relative;
  z-index: 3;

  .otooltip {
    position: absolute;
    top: 50%;
    left: 50% !important;
    transform: translateY(-50%);
    max-width: 100%;
    min-width: max-content;
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    margin-left: 0px !important;
    margin-top: -22px !important;
    padding: 0.4rem 0.6rem;

    border-radius: 6px;
    background-color: lightgray !important;
    color: black !important;

    visibility: hidden;
    opacity: 0;
    transition: opacity 400ms;
  }

  &:hover {
    .otooltip {
      opacity: 1;
      visibility: visible;
    }
  }
}

.custom-table-responsive {
  overflow-x: auto;
}

.custom-table-container {
  min-width: 600px;
}

.custom-table-header,
.custom-table-row {
  display: grid;
  grid-template-columns: 60px repeat(3, 1fr) 60px repeat(4, 1fr) 150px; /* default grid template */
  align-items: center;
  padding: 10px 0;
  font-size: 14px;
  place-items: center;
}

.custom-table-header.with-parampos,
.custom-table-row.with-parampos {
  grid-template-columns: 60px repeat(3, 1fr) 60px repeat(4, 1fr) 150px; /* grid template with ParamPos */
}

.custom-table-header div,
.custom-table-row div {
  text-align: center;
}

.custom-table-header {
  font-weight: 600;
  background-color: #f2f2f2;
  font-size: 15px;
}

.custom-table-row:nth-child(even) {
  background-color: #f9f9f9;
}

.custom-table-row:nth-child(odd) {
  background-color: #ffffff;
}

.custom-highlight {
  color: blue;
  white-space: nowrap;
}

.custom-radio-input {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-radio-input input {
  margin: 0;
}

.custom-price-display {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}
