table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
  font-size: 18px;
}

table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fcfcfc;
}

table.table-striped.table-hover tbody tr:hover {
  background: #f5f5f5;
}

table.table th i {
  font-size: 13px;
  margin: 0 5px;
  cursor: pointer;
}

.bannerekle {
  display: inline-block;
  text-align: right;
}

.bannerekle :hover {
  color: #a0a5b1;
  font-size: 19px;
}

table.table td:last-child {
  width: 130px;
}

table.table td a {
  color: #a0a5b1;
  display: inline-block;
  margin: 0 5px;
}

table.table td a.edit {
  color: #ffc107;
}

table.table td a.delete {
  color: #e34724;
}

table.table td i {
  font-size: 19px;
}

.pagination {
  float: right;
  margin: 0 0 5px;
}

.pagination li a {
  border: none;
  font-size: 95%;
  width: 30px;
  height: 30px;
  color: #999;
  margin: 0 2px;
  line-height: 30px;
  border-radius: 30px !important;
  text-align: center;
  padding: 0;
}

.pagination li a:hover {
  color: #666;
}

.pagination li.active a {
  background: #03a9f4;
}

.pagination li.active a:hover {
  background: #0397d6;
}

.pagination li.disabled i {
  color: #ccc;
}

.pagination li i {
  font-size: 16px;
  padding-top: 6px;
}

.hint-text {
  float: left;
  margin-top: 6px;
  font-size: 95%;
}

.bannerlist {
  margin-top: 25px;
  margin-left: 40px;
  margin-right: 50px;
}

.buttonsuz {
  margin-top: 5px;
}

.CellWithComment {
  position: relative;
}

.CellComment {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  padding: 3px;
  color: red;
  top: 20px;
  left: 20px;
}

.CellWithComment:hover span.CellComment {
  display: block;
}

.dealerSpecificPromotional {
  .campaignText {
    font-weight: 600;

    .link,
    b {
      color: #c51b3a;
      font-size: 16px;
    }

    .link {
      padding-left: 6px;

      &:hover {
        color: lighten(#c51b3a, 10);
      }
    }
  }

  .targetTable {
    width: 100%;
    background-color: #fff;
    z-index: 2;

    th {
      color: #c51b3a;
      font-weight: 700;

      &:first-of-type {
        width: 200px;
      }

      &:nth-of-type(2) {
        min-width: 400px;
      }

      &:nth-of-type(3) {
        width: 180px;
      }

      &:last-of-type {
        width: 50px !important;
        text-align: center;
      }
    }
  }

  .tableIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #c51b3a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#c51b3a, 10);
    }
  }

  .progress {
    background-color: #ddd;
    border-radius: 0;
  }

  .progress-bar {
    padding: 0;
  }

  .progressQuantity {
    position: absolute;
    inset: 0;
    margin: auto;
    width: max-content;
    height: max-content;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .progressQuantityMin {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #252525;
  }

  .progressQuantityMax {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #252525;
  }

  .campaignTable {
    min-width: 982px;
    height: max-content;

    tr {
      &:hover {
        cursor: pointer;
      }
    }

    th {
      font-size: 13px;
      color: #c51b3a;
      text-align: center;
      width: 16.66%;
      font-weight: 600;

      span {
        position: relative;
        bottom: 1px;
      }
    }

    td {
      font-size: 15px;
    }

    .inputRow td {
      padding: 0 !important;
    }

    input {
      border: none !important;
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }

  .campaignModalWrapper {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 60%);
    cursor: pointer;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: visibility, opacity, 0.4s;

    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .campaignModal {
    position: absolute;
    inset: 0;
    margin: auto;
    max-width: 900px;
    height: max-content;
    max-height: 80vh;
    background-color: #fff;
    padding: 1rem;
    z-index: 300 !important;
  }

  .arrowUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #c51b3a;
  }

  .arrowDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #c51b3a;
  }
}

.SwitchRoot {
  all: unset;
  width: 42px;
  height: 25px;
  background-color: #cecece;
  border-radius: 9999px;
  position: relative;
  box-shadow: 0 2px 10px #cecece;
  -webkit-tap-highlight-color: #81c868;
}

.SwitchRoot:focus {
  box-shadow: 0 0 0 2px #81c868;
}

.SwitchRoot[data-state='checked'] {
  background-color: #81c868;
}

.SwitchThumb {
  display: block;
  width: 21px;
  height: 21px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}

.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
}

.Label {
  color: white;
  font-size: 15px;
  line-height: 1;
}

.tab {
  background-color: #ccc;
  color: white;
  border-radius: 21.5px 21.5px 0 0 !important;
  padding: 10px 20px;
  cursor: pointer;
}

.active-tab {
  background-color: rgba(218, 41, 28);
}