.col-1-5 {
  position: relative;
  width: 100%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.admin-home .form-control.admin-home-button-left {
  border-radius: 21.5px 21.5px 0 0 !important;
}

.admin-home .form-control.admin-home-button-left,
.admin-home .form-control.admin-home-button-right {
  background-color: rgba(15, 189, 224, 0.2);
  color: #616161;
  font-weight: 500;
}

.admin-home .form-control.admin-home-button-right {
    border-radius: 21.5px 21.5px 0 0 !important;
}

.admin-home .table-title {
  margin: 20px 0;
}

.admin-home .table-title,
.admin-home .board-title {
  font-size: 17px;
  font-weight: 500;
  color: #616161;
}

.admin-home .table-bordered {
  box-shadow: 0px 4px 90px rgba(163, 171, 185, 0.14);
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e0e0e2;
}

.admin-home .table-bordered td {
  border-top: none;
  border-bottom: 1px solid #e0e0e2 !important;
}

.admin-home .board-subtitle {
  color: #a1a3a8;
  font-weight: 400;
  display: block;
  font-size: 1rem;
  height: 25px;
}

.admin-home .board-title {
  margin: 20px 0 0 0;
}
