.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.productDetailsSlider .mySwiper img {
  object-fit: contain !important;
}

.productDetailsSlider .mySwiper .swiper-button-prev,
.productDetailsSlider .mySwiper .swiper-button-next,
.productCard .productImage .next,
.productCard .productImage .prev {
  opacity: 0;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev,
.productCard:hover .next,
.productCard:hover .prev {
  opacity: 1;
  transition: 0.5s ease;
}

.swiper-slide img:not(.productButton img, .productInfoBox img, .swiper .brandItem img, .indicator img, .action img) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.productDetailsSlider .swiper img {
  max-height: 500px;
}

.productCard .productButton {
  position: relative;
  top: -3rem;
  width: 157px;
  margin-inline: auto;
}
