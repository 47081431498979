.ProductTitle {
  font-weight: 500;
  font-size: 28px;
  color: #616161;
  margin-bottom: 30px;
}
.ProductName {
  font-size: 20px;
  font-weight: 400;
  color: #9b9b9b;
  margin-bottom: 30px;
}
.ProductLine {
  width: 143px;
  height: 3px;
  background-color: #ee333e;
  margin-bottom: 58px;
}

.ProductProperty {
  color: #b4b4b4;
  font-size: 22px;
  font-weight: 500;
}
.ProductValue {
  color: #616161;
  font-weight: 500;
}

.mem {
  color: red;
  &:hover {
    color: red;
  }
}
@media only screen and (max-width: 600px) {
  .mobiL {
    width: 100%;
    font-size: xx-small;
  }
  .mobiLText {
    font-size: xx-small;
    width: 70%;
  }
}
