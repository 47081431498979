.fcvWrap {
  background-color: #efeff6;
  min-height: 100vh;
  padding: 1rem 0px;
  box-sizing: border-box;

  .listBtn {
    background-color: #fff;
    color: #699bf7;
    width: auto;
    font-size: 0.9vw;
    padding: 0.5rem 1rem;
    border-radius: 10px;
  }

  .arrow-left {
    position: relative;
    left: 8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #699bf7;
  }

  .saveBtn {
    background-color: #fff;
    color: #197e00;
    border: 1px solid transparent;
    width: auto;
    font-size: 1vw;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;

    &:hover {
      border: 1px solid #197e00;
    }

    &:active {
      border: 1px solid #197e00;
      background-color: #e0fad9;
    }
  }

  .formTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #000;
    margin: 1rem 0;
  }

  .formCard {
    background-color: #fff;
    padding: 2rem 2.5rem;
    border-radius: 25px;

    &.cariForms {
      display: grid;
      grid-template-columns: 30% 1fr;
      gap: 2rem;
    }
  }

  .btnIncluded,
  .btnExcept {
    background: #f5f5f5;
    color: #333;
    width: 80px;
    min-width: auto;
    transition: 0.3s;

    &.active {
      background-color: #699bf7;
      color: #fff;
    }

    &:hover {
      background-color: darken(#699bf7, 5);
      color: #fff;
    }
  }

  .btnIncluded {
    border-radius: 6px 0px 0px 6px;
  }

  .btnExcept {
    border-radius: 0px 6px 6px 0px;
  }

  .topBtnGroup {
    width: 100%;
  }

  .cariFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .leftContainer {
    display: grid;
    grid-template-columns: 150px 1fr;
    align-items: flex-end;
    width: 100%;

    .listCariBtn {
      background: #f6f6f6;
      color: #000;
      width: 100%;
      border: 1px solid #efeff6;
      justify-content: flex-start;
      padding-left: 1rem;
      font-size: 12px;
      height: 60px;

      &.active {
        background: #efeff6;
        border: 1px solid #699bf7;
        color: #699bf7;
      }
    }

    .cariFilterTextAreaWrap {
      display: none;

      &.active {
        display: block;
      }
    }

    .cariFilterList {
      width: 100%;
      height: 133px;
      border: 1px solid #ccc;
    }
  }

  .rightContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    .form-check-input:checked {
      background-color: #699bf7;
      border-color: #699bf7;
    }

    .campaignLabel {
      font-size: 12px;
    }
  }

  .topArea {
    display: block;
    background-color: #f6f6f6;
    padding: 1rem;

    // @media (max-width: 1440px) {
    //   grid-template-columns: 1fr;
    //   gap: 2rem;
    //   margin-left: 0px;
    // }
  }

  .topAreaListGroup {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    gap: 0.5rem;

    // @media (max-width: 1275px) {
    //   grid-template-columns: 1fr 1fr 1fr
    // }

    .btnTopArea {
      border: 1px solid #699bf7;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 6px;
      color: #699bf7;
      width: 55px;
      min-width: auto;
      height: 32px;
      font-size: 13px;

      &.active {
        background-color: #699bf7;
        color: #fff;
      }
    }

    .topAreaInputGroup {
      // border-bottom: 1px solid #e1e1f1;
      width: 100%;
    }

    // .topAreaInputGroup span {
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   right: 5px;
    //   font-size: 28px;
    //   cursor: pointer;
    // }

    // .topAreaInputGroup input {
    //   border-top-left-radius: 15px;
    //   border-top-right-radius: 15px;
    //   height: 34px;
    // }

    .divArea {
      height: 90px;
      padding: 1rem;
      overflow-y: auto;
    }
  }

  .topAreaQuantityGroup {
    background-color: #efeff6;
    border-radius: 15px;
    margin-top: 2rem;

    .contentArea {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;

      .contentCard .divTitle {
        min-width: 150px;
      }

      // .minMaxWrapper {
      //   display: flex;
      // }

      .contentCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 8px;
        font-size: 15px;

        .title {
          padding: 0.5rem 1rem;
          border: 1px solid transparent;
          background-color: #fff;
          border: 1px solid #699bf7;
          border-radius: 6px;

          &.active {
            background-color: #699bf7;
            color: #fff;
          }
        }

        .contentCardInputArea {
          display: flex;
          gap: 0.5rem;
          margin-top: 1rem;
          height: 47px;
        }

        .contentCardInputArea input {
          display: block;
          background-color: hsl(0, 0%, 100%);
          border-color: hsl(0, 0%, 80%);

          &:first-of-type {
            width: 30%;
          }

          &:nth-of-type(2) {
            width: 30%;
          }

          &:focus {
            border: none;
          }

          &:disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: hsl(0, 0%, 90%);
          }
        }
      }
    }

    .btnContentArea {
      width: 45%;
      margin-bottom: 0.8rem;
    }
  }

  .bottomArea {
    background-color: #f6f6f6;
    padding: 1rem;

    .bottomAreaFirst div input {
      height: 100%;
      border: none;
    }
  }

  .campaignModalWrapper {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 60%);
    cursor: pointer;
    z-index: 100;

    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: visibility, opacity, 0.4s;

    &.active {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }

  .campaignModal {
    position: absolute;
    inset: 0;
    margin: auto;
    max-width: 1410px;
    min-height: max-content;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #fff;
    padding: 4rem 3rem;
    border-radius: 1rem;
  }

  .modalBtn {
    width: 150px;
    display: inline-block;
    margin-top: 1rem;
    margin-right: 6px;
    background-color: #699bf7;

    &:hover {
      background-color: darken(#699bf7, 5);
    }
  }

  .campaignTable {
    min-width: 982px;
    height: max-content;

    tr:last-of-type {
      cursor: pointer;
    }

    th {
      font-size: 13px;
      color: #699bf7;
      text-align: center;
      font-weight: 600;

      div {
        text-transform: uppercase;
      }

      span {
        position: relative;
        bottom: 1px;
      }
    }

    td {
      font-size: 15px;
    }

    .inputRow td {
      padding: 0 !important;
    }

    input {
      border: none !important;
      width: 100%;
      height: 100%;
      font-size: 14px;
    }
  }

  .actionCol {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100% !important;
  }

  .tableIcon {
    margin-left: 6px;
    display: inline-grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #699bf7;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#699bf7, 10);
    }
  }

  .campaignExcelBtn {
    width: max-content;
    height: 44px;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #699bf7;
    border-radius: 6px;
    border: 1px solid #699bf7;
    font-size: 15px;
    font-weight: 600;
    padding-inline: 1rem;
    margin-bottom: 1.5rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #699bf7 !important;
      color: #fff !important;
    }
  }

  .campaignFileInput {
    background-color: #699bf7 !important;
    outline: 1px solid #fff !important;
    border-radius: 6px;
    height: 44px;
    display: flex;
    align-items: center;
    padding-block: unset;
    transition: background-color 0.3s;
    margin-right: 1rem;

    &:hover {
      background-color: darken(#699bf7, 5) !important;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}