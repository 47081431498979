.orderReturn {
  .tableIcon {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    background-color: #c51b3a;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: lighten(#c51b3a, 10);
    }
  }
}
