.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  padding: 1rem;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  // width: 500px;
  background-color: #fff;
  transition: opacity 0.4s ease-in-out;
  border: none !important;
  border-radius: 1rem;
  max-height: 90vh;
}

.modal.enter-done .modal-content {
  opacity: 1;
}

.modal.exit .modal-content {
  opacity: 0;
}

.modal-header {
  padding: 6px 10px;
}

.modal-footer {
  padding: 3px;
}

.modal-title {
  margin: 0;
}

.modal-body {
  padding: 10px;
  overflow-y: auto !important;
}

.modal-button {
  min-width: auto;
  width: 100px;
  height: 33px;
}