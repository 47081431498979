.home-page-slider .btn.banner-btn {
  background: #ffffff !important;
  border: none !important;
  color: black !important;
  border-radius: 25px !important;
  padding: 14px !important;
  color: #616161 !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  margin-bottom: 0 !important;
}

.home-page-slider .banner-list li {
  max-width: 100%;
}

.home-page-slider .tabs .nav-tabs .nav-link {
  padding: 0 24px;
}

.home-page-slider .swiper-button-prev,
.home-page-slider .swiper-button-next {
  background: rgb(209, 205, 205);
  opacity: 0;
  border-radius: 5px;
  padding: 15px 20px;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  opacity: 1;
  transition: 0.5s ease;
}

.home-page-slider .swiper-button-next:after,
.home-page-slider .swiper-button-prev:after {
  font-size: 0.8rem !important;
  font-weight: 700;
  color: #1f4173;
}

.home-page-slider .banner-brand-btn {
  width: 60px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #ee333e;
  box-sizing: border-box;
  border-radius: 10px;
}

.home-page-slider .swiper-slide {
  align-items: normal;
  height: 100%;
}

.home-page-slider .tabs .nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
}

.home-page-slider .tabs .nav-tabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  color: #616161;
  font-weight: 600;
  font-size: 17px;
}

.home-page-slider .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.home-page-slider .swiper-pagination-bullet {
  background: #000;
  opacity: 0.2;
}

.home-page-slider .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 0.8;
}

@media (max-width: 800px) {
  .home-page-slider .tabs .nav-tabs .nav-link {
    font-size: 1.1rem;
  }

  .home-page-slider .tabs .nav-tabs .nav-link {
    padding: 10px 8px;
  }

  .home-page-slider .tabs .nav-tabs {
    margin-bottom: 1rem !important;
  }
}

.banner {
  display: flex;
  gap: 1rem;

  &:has(.banner__small) .banner__main {
    width: calc(65% - 0.5rem);
  }

  @media (max-width: 992px) {
    flex-direction: column-reverse;

    &:has(.banner__small) .banner__main {
      width: 100%;
    }
  }
}

.banner__small {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: calc(35% - 0.5rem);

  @media (min-width: 993px) {
    aspect-ratio: 1;
  }

  @media (max-width: 992px) {
    width: 100%;
    flex-direction: row;
  }
}

.banner__swiper {
  width: 100%;
  height: 50%;

  @media (max-width: 992px) {
    width: calc(50% - 0.15rem);
    height: auto;
  }
}

.banner__main {
  width: 100%;
}
