.StopWatch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ee333e;
  background-size: cover;
  width: 100%;
  //height: 140px;
  max-height: 140px;
  gap: 1rem;
  padding: 6px;

  @media (max-width: 1024px) {
    background-size: contain;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    max-height: auto;
    background-image: none;
    background-color: #ee333e;
  }
}

.StopWatchText {
  color: #fff;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 500;
  padding: 1rem;
  background-color: transparent;
  margin-left: 50px;

  @media (max-width: 1024px) {
    align-self: center;
    margin-left: 0;
    background-color: transparent;
  }
}

.InnerCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 69px;
  height: 69px;
  background-color: #181a1c;
  border-radius: 50%;
  color: #fff;
}

.TimerValue {
  font-size: clamp(1.2rem, 3vw, 1.6rem);
  line-height: clamp(0.8rem, 3vw, 1.4rem);

  @media (max-width: 1024px) {
    position: relative;
    top: 5px;
  }
}

.TimerText {
  font-size: clamp(1rem, 3vw, 1.2rem);
}

.arrowLeft {
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
  width: 20px;
  height: 20px;
  display: block;
  transform: rotate(45deg);
}

.arrowRight {
  border-right: 5px solid #fff;
  border-bottom: 5px solid #fff;
  width: 20px;
  height: 20px;
  display: block;
  transform: rotate(-45deg);
}
