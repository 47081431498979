#footer {
  background-color: #333333;
}

#footer .icon-boxxx {
  display: block;
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-bottom: none;
  max-width: 100%;
}

// #footer .icon-boxx:nth-child(2n) img {
//   transform: translateY(50%);
// }
#footer .widget h4 {
  font-size: 1.3rem;
}

#footer .seperator::before,
#footer .seperator::after {
  border-bottom: 1px solid #ababaf;
}

#footer .footer-content.bottom_footer .list a {
  color: #a09b97 !important;
  font-size: 1.1rem;
}

#footer .footer-content.bottom_footer .widget h4 {
  color: #fff !important;
  font-weight: 600 !important;
}

#footer .footer-content.bottom_footer .widget .social-icons li i {
  color: #a09b97 !important;
  font-size: 1.8rem;
}

#footer .footer-content.bottom_footer .widget .social-icons li a:hover {
  background-color: transparent;
}

#footer .footer-content.bottom_footer .social-icons li {
  margin-right: 15px;
}

#footer .event-info-box .col-lg-3 li {
  padding: 0 50px;
}

#footer .content .grid li:before {
  border-left: 1px solid #ccc;
}

#footer .content .grid li:hover {
  cursor: pointer;
  background-color: transparent;
}

#footer .content .grid li {
  padding: 20px 40px;
}

#footer .backToTop {
  border: 2px solid #ababaf;
  text-transform: initial;
}

#footer .icon-box h3 {
  color: #ee333e;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 36px;
}

#footer .widget-subscribe-form input {
  background-color: transparent;
  min-height: auto;
  height: auto;
  border-radius: 0;
  margin-right: 20px;
  border-color: #e0e0e2;
}

#footer .widget-subscribe-form input::placeholder {
  font-size: 12px;
  color: white;
}

#footer .logo_button {
  padding: 0 !important;
}

#footer .list li {
  margin-bottom: 20px;
  font-size: 1.1rem;
}

#footer .widget-subscribe-form #widget-subscribe-submit-button {
  background-color: transparent;
  border-color: #e0e0e2;
  border-radius: 0;
  text-transform: capitalize;
  font-weight: 500;
}

#footer .widget-subscribe-form button.btn,
.btn:not(.close):not(.mfp-close) {
  margin-bottom: 0;
}

#footer .iconGlobe i {
  color: #a09b97;
  font-size: 20px;
}

#footer .footer-bottom-images {
  background-color: #f0f0f1;
}

#footer .footer-bottom-images .text-box {
  border-right: 2px solid #e0e0e2;
}
