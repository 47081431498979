.btnIncluded,
.btnExcept {
  background: #f5f5f5;
  color: #333;
  width: 80px;
  min-width: auto;
  transition: 0.3s;

  &.active {
    background-color: #699bf7;
    color: #fff;
  }

  &:hover {
    background-color: darken(#699bf7, 5);
    color: #fff;
  }
}

.btnIncluded {
  border-radius: 6px 0px 0px 6px;
}

.btnExcept {
  border-radius: 0px 6px 6px 0px;
}

.topBtnGroup {
  width: 100%;
}

.cariFilterContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.leftContainer {
  display: grid;
  grid-template-columns: 150px 1fr;
  //align-items: flex-end;
  width: 100%;

  .listCariBtn {
    background: #f6f6f6;
    color: #000;
    width: 100%;
    border: 1px solid #efeff6;
    justify-content: flex-start;
    padding-left: 1rem;
    font-size: 12px;
    height: 60px;

    &.active {
      background: #efeff6;
      border: 1px solid #699bf7;
      color: #699bf7;
    }
  }

  .cariFilterTextAreaWrap {
    display: none;
    width: 250px;

    &.active {
      display: block;
      width: 250px;
    }
  }

  .cariFilterList {
    width: 100%;
    height: 133px;
    border: 1px solid #ccc;
  }
}

.form-control {
  font-size: 16px !important;
}

.btnstyle {
  float: right;
}

.formCard {
  background-color: #fff;
  padding: 2rem 2.5rem;
  border-radius: 25px;

  &.cariForms {
    display: grid;
    grid-template-columns: 50% 1fr;
    gap: 2rem;
  }
}

.campaignExcelBtn {
  width: max-content;
  height: 44px;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #699bf7;
  border-radius: 6px;
  border: 1px solid #699bf7;
  font-size: 15px;
  font-weight: 600;
  padding-inline: 1rem;
  margin-bottom: 1.5rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #699bf7 !important;
    color: #fff !important;
  }
}

.campaignFileInput {
  background-color: #699bf7 !important;
  outline: 1px solid #fff !important;
  border-radius: 6px;
  height: 44px;
  display: flex;
  align-items: center;
  padding-block: unset;
  transition: background-color 0.3s;
  margin-right: 1rem;

  &:hover {
    background-color: darken(#699bf7, 5) !important;
  }
}

.campaignTable {
  min-width: 982px;
  height: max-content;

  tr:last-of-type {
    cursor: pointer;
  }

  th {
    font-size: 13px;
    color: #699bf7;
    text-align: center;
    font-weight: 600;

    div {
      text-transform: uppercase;
    }

    span {
      position: relative;
      bottom: 1px;
    }
  }

  td {
    font-size: 15px;
  }

  .inputRow td {
    padding: 0 !important;
  }

  input {
    border: none !important;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
}

.actionCol {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100% !important;
}

.tableIcon {
  margin-left: 6px;
  display: inline-grid;
  place-items: center;
  width: 30px;
  height: 30px;
  background-color: #699bf7;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: lighten(#699bf7, 10);
  }
}

.textarea {
  height: 100px;
}

.textareabayikod {
  height: 140px;
}

.whiteDealer {
  .leftContainer {
    display: grid;
    grid-template-columns: 150px 1fr;
    // align-items: flex-end;
    width: 100%;

    .listCariBtn {
      background: #f6f6f6;
      color: #000;
      width: 100%;
      border: 1px solid #efeff6;
      justify-content: flex-start;
      padding-left: 1rem;
      font-size: 12px;
      height: 60px;

      &.active {
        background: #efeff6;
        border: 1px solid #699bf7;
        color: #699bf7;
      }
    }

    .cariFilterTextAreaWrap {
      display: none;
      width: unset !important;

      &.active {
        display: block;
      }
    }

    .cariFilterList {
      width: 100%;
      height: 133px;
      border: 1px solid #ccc;
    }
  }

  .campaignExcelBtn {
    width: max-content;
    height: 44px;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: #699bf7;
    border-radius: 6px;
    border: 1px solid #699bf7;
    font-size: 15px;
    font-weight: 600;
    padding-inline: 1rem;
    margin-bottom: 1.5rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #699bf7 !important;
      color: #fff !important;
    }
  }

  .campaignFileInput {
    background-color: #699bf7 !important;
    outline: 1px solid #fff !important;
    border-radius: 6px;
    width: max-content;
    height: 44px;
    display: flex;
    align-items: center;
    padding-block: unset;
    transition: background-color 0.3s;
    margin-right: 1rem;

    &:hover {
      background-color: darken(#699bf7, 5) !important;
    }
  }
}
