.licence {
  animation: beat .75s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.3);
  }
}

.custom-icon::before {
  content: '';
  display: inline-block;
  width: 84px;
  /* İkonun genişliğini belirleyin */
  height: 84px;
  /* İkonun yüksekliğini belirleyin */
  background-image: url('../../../public/images/products/kirmizi_ikon-01.jpg');
  /* İkonun yolunu doğru şekilde belirtin */
  background-size: cover;
  /* Arka plan boyutunu ayarlayın */
}