.CellWithComment {
  position: relative;
}

.CellComment {
  display: none;
  position: absolute;
  z-index: 100;
  border: 1px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: red;
  padding: 3px;
  color: red;
  top: 20px;
  left: 20px;
}

.CellWithComment:hover span.CellComment {
  display: block;
}

.ssh,
.sshYetkili {
  cursor: pointer;
  border-radius: 5px;
}

.ssh-hover,
.sshYetkili-hover {
  display: none;
}

.image-hover:hover .ssh,
.image-hover:hover .sshYetkili {
  display: none;
}

.image-hover:hover .ssh-hover,
.image-hover:hover .sshYetkili-hover {
  display: inline-block;
}