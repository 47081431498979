.financialDocuments {
  h1 {
    font-size: clamp(1rem, 4vw, 3rem);
    color: #ec4c4c;
  }

  .titleWithBorder {
    display: flex;
    margin: 2rem 0;
    padding: 1rem 1.5rem 0.5rem;
    border: 1px solid #ccc;
    font-weight: 600;
  }

  .titleBorderless {
    display: flex;
    margin: 1.2rem 0;
    font-weight: 600;
    font-size: 18px;
    font-weight: 600;
    border: none;
  }

  .files {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem 1rem;

    .file {
      display: flex;
      align-items: center;
      padding: 0.4rem 0;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      color: inherit;
      text-align: left;
      transition: color, border, 0.3s ease-out;
      font-size: 15px;

      &:hover {
        color: #ec4c4c;
        border: 1px solid #ccc;
      }
    }
  }
  // .files {
  //   display: grid;
  //   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  //   gap: 2rem 1rem;

  //   .file {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     gap: 1rem;
  //     padding: 1rem 0.5rem;
  //     border: 1px solid transparent;
  //     font-size: 14px;
  //     font-weight: 500;
  //     color: inherit;
  //     text-align: center;
  //     transition: color, border, 0.3s ease-out;

  //     &:hover {
  //       color: #ec4c4c;
  //       border: 1px solid #ccc;
  //     }
  //   }
  // }
}
