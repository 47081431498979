.aboutUs .aboutTitle,
.aboutUs .tabs li a:hover {
  color: #dc3545;
}

.aboutUs .tabs .tab-content .tab-pane .heading-text p {
  font-size: 1.3rem;
}

.aboutUs table.table td:last-child {
  width: 600px;
}

.aboutUs table.table .cart-product-name {
  border-right: 1px solid #e9e9e9;
  height: 80px;
}

.aboutUs .table-responsive h5 {
  text-align: center;
}

.aboutUs .table td {
  vertical-align: middle;
}

.aboutUs .cart-product-name a {
  color: #212529;
}

.aboutUs .sr-only-size {
  font-size: 1.5rem !important;
}

.aboutUs .IPButton_Button__GWRch {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  width: 100%;
  height: 44px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #ec4c4c;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 3px;
  transition: background-color 0.3s;
}

.aboutUs .nav-tabs a:hover {
  color: rgba(72, 72, 72, 0.8) !important;
}

.aboutUs .form-group {
  position: relative;
  margin-bottom: 2.5rem !important;
}
