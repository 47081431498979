.dealerChannelProgram {
  .authorizedServicePoints .heading-text h4 {
    font-size: 1.9rem;
    font-weight: 500;
    margin-bottom: 0;
    color: #616161;
  }

  .authorizedServicePoints table.table tr td {
    width: 200px;
    max-width: 200px;
    min-width: 200px;
    vertical-align: middle;
  }

  .search {
    position: relative;

    .icon {
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    input {
      display: block;
      max-width: 285px;
      margin-left: auto;
      background-color: #f0f0f1;
      font-size: 17px;
      padding-right: 40px;

      &::placeholder {
        position: relative;
        top: 2px;
        font-size: 18px;
        color: #ababaf;
      }
    }
  }

  @media (max-width: 767px) {
    .dealerChannel #datatb_filter input {
      width: 100%;
    }
  }
}
