.visualActive {
  width: 20px !important;
}
.visualActiveCol {
  align-items: center;
  justify-content: center;
  margin: auto;
}
.form-check {
  padding-left: 1.5em;
}

.visual-documentation {
  background: white;
  box-shadow: 0px 4px 90px rgb(163 171 185 / 24%);
  padding: 40px 20px;
  min-height: 420px;
  height: 420px;
  cursor: move;
}

#draggable {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  font-size: 2rem;
}

.no-select {
  user-select: none;
}
