@media (max-width: 767px) {
  .nav {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 15px !important;
  }
}

.table-responsive {
  width: 100% !important;
}

.company-tab {
  cursor: pointer;
  margin-right: 2rem !important;
  padding: 4px 8px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
}

.company-tab:hover {
  background-color: red;
  color: #fff;
}

.active-company-tab {
  background-color: rgba(218, 41, 28);
  /* Green color for USD */
  color: #fff;
}

.active-company-tab:hover {
  background-color: #d83232;

  span {
    color: #fff;
  }
}

.active-company-tab:nth-child(2) {
  background-color: #17b3dc;
}

.active-company-tab:nth-child(2):hover {
  background-color: #3cafcc;

  span {
    color: #fff;
  }
}

.active-company-tab:nth-child(3) {
  background-color: #2693d1;
}

.active-company-tab:nth-child(3):hover {
  background-color: #3391c7;

  span {
    color: #fff;
  }
}

.active-company-tab:nth-child(4) {
  background-color: #0b4c8f;
}

.active-company-tab:nth-child(4):hover {
  background-color: #1c5691;

  span {
    color: #fff;
  }
}

.company-tab span:hover {
  color: #fff !important;
}

.invoice-input {
  padding: 4px 6px !important;
}

.company-tab:nth-child(1):hover {
  background-color: #d83232;
}

.company-tab:nth-child(2):hover {
  background-color: #17b3dc;
}

.company-tab:nth-child(3):hover {
  background-color: #2693d1;
}

.company-tab:nth-child(4):hover {
  background-color: #0b4c8f;
}

.currency-tab {
  cursor: pointer;
  margin-right: 2rem !important;
  padding: 4px 8px;
  background-color: #fff;
  color: #000;
  border-radius: 10px;
}

.currency-tab:hover {
  background-color: #182747;
  color: #fff;
}

.currency-tab span:hover {
  color: #fff;
}

.invoice-input {
  /* input {
    padding: 4px 6px !important;
  } */
}

.active-currency-tab {
  background-color: #008000;
  /* Green color for USD */
  color: #fff;
}

.active-currency-tab:hover {
  background-color: #299729;

  /* Light green for USD hover */
  span {
    color: #fff;
  }
}

.active-currency-tab:nth-child(2) {
  background-color: #da291c;
  /* Red color for TRY */
}

.active-currency-tab:nth-child(2):hover {
  background-color: #d83232;

  /* Light red for TRY hover */
  span {
    color: #fff;
  }
}

.active-currency-tab:nth-child(3) {
  background-color: #182747;
  /* Blue color for EUR */
}

.active-currency-tab:nth-child(3):hover {
  background-color: #354c80;

  /* Light blue for EUR hover */
  span {
    color: #fff;
  }
}

.active-currency-tab:nth-child(4) {
  background-color: #ce041c;
  /* color for Hepsi */
}

.active-currency-tab:nth-child(4):hover {
  background-color: #da2439;

  /* Light for Hepsi hover */
  span {
    color: #fff;
  }
}

.currency-tab:nth-child(1):hover {
  background-color: #299729;
  /* Light green for USD hover */
}

.currency-tab:nth-child(2):hover {
  background-color: #ff0000;
  /* Light red for TRY hover */
}

.currency-tab:nth-child(3):hover {
  background-color: #354c80;
  /* Light blue for EUR hover */
}

.currency-tab:nth-child(4):hover {
  background-color: #da2439;
  /* Light for hepsi */
}

.cari-hesap--row {
  display: flex;
  gap: 1rem;
}

.cari-hesap--col {
  background-color: #efefef;
  padding: 13px 20px;
  width: 240px;
}

.cari-hesap--tab-menu {
  border: 0;
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: 2rem;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
  }

  li {
    margin: 0 !important;
  }
}