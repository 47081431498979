.menuCol {
  position: relative;
  box-sizing: border-box;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fff;
  width: calc(20% - 0.8rem);
}

.menuTitle {
  padding: 3px 1rem 0;
  background-color: #e7e7e7;
  color: #252525;
  font-size: 16px;
  font-weight: 600;
}

.menuItem {
  display: block;
  padding: 2px 1rem;
  font-size: 15px;
  color: #252525;
  background-color: #fff;

  border-bottom: 1px solid rgb(228, 225, 225);

  &:first-child {
    border: 2px solid red;
  }

  .Link {
    color: #252525;
  }
}
