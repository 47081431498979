.homePage-header .swiper {
  width: 100%;
  height: 100%;
  margin: 25px 0;
}

.homePage-header .swiper .swiper-button-next {
  right: 0px;
}

.homePage-header .swiper .swiper-button-prev {
  left: 0px;
}

.homePage-header .swiper-wrapper {
  display: flex;
  align-items: center;
}

.homePage-header .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: center;
  flex-direction: column;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.homePage-header .brandItem {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 70px;
    height: unset;
    min-width: 50px;
  }
}

.brand-white-background {
  width: 70px;
  height: 70px;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homePage-header .customLeftArrow,
.homePage-header .customRightArrow {
  border-bottom: 4px solid #c4c4c4;
  position: absolute;
  top: 42%;
  width: 12px;
  height: 12px;
  display: block;
  cursor: pointer;
  z-index: 10;
  box-sizing: content-box;
}

.homePage-header .customLeftArrow {
  left: 1.6rem;
  border-left: 4px solid #c4c4c4;
  transform: rotate(45deg);
}

.homePage-header .customRightArrow {
  right: 1.6rem;
  border-right: 4px solid #9fadc1;
  transform: rotate(-45deg);
}

@media (max-width: 800px) {
  .homePage-header .brandItem img {
    width: 40px;
    min-width: 40px;
  }

  .homePage-header .carousel-title {
    font-size: 1rem;
  }
}

.swiper-button-next,
.swiper-button-prev {
  transition: opacity 0.5s;
}

.swiper-button-disabled {
  opacity: 0 !important;
  cursor: default !important;
}
