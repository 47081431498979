@mixin font-adjustment {
  descent-override: 22%;
  ascent-override: 85%;
  // line-gap-override: 3%;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Thin.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Light.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Book.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-BookItalic.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-BookItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Regular.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Bold.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Bold.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-XBold.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-XBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

@font-face {
  font-family: 'Nexa';
  src: url('../public/assetWeb/webfonts/Nexa/Nexa-Black.woff2') format('woff2'),
    url('../public/assetWeb/webfonts/Nexa/Nexa-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  @include font-adjustment;
}

html {
  font-size: 12px;
}

html,
body {
  height: 100%;
  font-family: 'Nexa', sans-serif;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 1.3rem;
}

a {
  text-decoration: none;
}

a,
a:hover {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

img {
  max-width: 100%;
  max-height: 100%;
}

section {
  padding: 20px 0;
  background: #f5f5f5;
}

.rounded {
  border-radius: 20px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.room-image {
  min-height: 172px;
  height: 172px;
}

.breadcrumb-item {
  font-size: 15px;
  font-weight: 600;
}

.breadcrumb ol .breadcrumb-item+li:before,
.breadcrumb ol li+li:before {
  content: '/';
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1440px;
  }
}

input:not([type='checkbox']):not([type='radio']):not([type='file']),
select {
  border: 1px solid hsl(0, 0%, 80%);
  width: 100%;
  padding: 10px 16px;
  font-size: 1rem;
  color: #616161;
  outline: none;
  border-radius: 0;

  &:focus {
    border: 1px solid #818181;
  }
}

.swiper-pagination-bullet-active {
  background-color: #ec4c4c;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d3d2d2;
}

::-webkit-scrollbar-thumb:hover {
  background: #7a7a7a;
}

.dataTables_length label {
  width: 245px;
  margin-left: 10px;
}

.tableIcon {
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #699bf7;
  position: relative;

  &:hover {
    background-color: lighten(#699bf7, 10);
  }

  &.edit {
    background-color: #81c868;

    &:hover {
      background-color: lighten(#81c868, 10);
    }
  }

  &.delete {
    background-color: #d45151;

    &:hover {
      background-color: lighten(#d45151, 10);
    }
  }
}