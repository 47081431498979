.productCardGrid2 {
  grid-template-columns: 1fr;
}

.productCard2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 1rem;
  min-height: 110px;
  padding-inline: 35px;

  .productTextWrapper {
    width: 300px;
  }

  .productImage {
    display: grid;
    place-items: center;
    width: 90px;
    height: 90px;
    overflow: hidden;

    img {
      display: block;
      max-width: 100%;
      max-height: 100px;
    }
  }

  .productName {
    margin-bottom: 1rem;
    line-height: 18px;
  }

  .productCode {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    height: 20px;
    line-height: 8px;
  }

  .iQuote {
    padding-block: 0.5rem;
  }

  .circle {
    display: grid;
    place-items: center;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  .seller {
    display: block;
    max-height: 20px;
  }

  .productPrices {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    font-weight: 500;
    max-width: 125px;
  }

  .priceTitle {
    font-size: 12px;
    color: #616161;
    margin-bottom: 15px;
    margin-right: 0.5rem;
  }

  .priceTitle--gray {
    color: #ababaf;
  }

  .price {
    font-size: 13px;
    float: right;
  }

  .price-none-decoration {
    text-decoration: none;
  }

  .stock {
    font-size: 12px;
    font-weight: 500;
    color: #ababaf;

    span {
      font-size: 14px;
    }
  }

  .stockNumber {
    width: 86px;
    height: 30px !important;
    min-height: 28px !important;
    padding: 0 !important;
    padding-top: 5px !important;
    text-align: center;
  }

  .productButton2 {
    min-width: unset;
    width: 100%;
    height: 35px;
    font-size: 12px;
    margin-top: 6px;
    line-height: 13px;
    text-align: left;
    padding-left: 0.7rem;
  }

  .actions {
    position: relative;
    inset: 0;
  }
}
