.productBrand2 {
  position: relative;
  display: grid;
  place-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.productImagePopup {
  width: 100px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1px;
  z-index: 10;
  border: 1px solid #a1a3a8;

  transform: scale(0);
  visibility: hidden;
  transition: transform, visibility, 0.3s;

  .productImage {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      max-height: 68px !important;
      object-fit: contain;
    }
  }

  &.active {
    transform: scale(1);
    visibility: visible;
  }
}
