.Reports {
  form {
    .formElements {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
    }

    .formEl {
      max-width: calc(50% - 1rem);
      width: 100%;

      @media (max-width: 780px) {
        max-width: 100%;
      }

      label {
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #616161;
        margin-bottom: 6px;
      }
      input:not([type='checkbox']),
      select {
        border: 1px solid #e0e0e2;
        border-radius: 0;
        width: 100% !important;
        height: 43px !important;
      }
    }
  }

  .myOrderActions {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-block: 3rem;

    @media (max-width: 790px) {
      flex-wrap: wrap;
    }
  }

  .button {
    width: 150px;
    background-color: #fff;
    color: #ec4c4c;
    font-size: 18px;
    border: 1px solid #ee333e;

    &:hover {
      background-color: #ee333e;
      color: #fff;
    }
  }

  .marketPlace {
    width: 100%;
    padding: 0 15px;
    border: 1px solid #e0e0e2;

    b {
      font-size: 18px;
      font-weight: 500;
      color: #616161;
    }

    .sellerImage {
      width: 65px;
    }

    .marketPlaceActions {
      color: #ee333e;
      font-size: 18px;
      font-weight: 500;
    }

    .text-green {
      color: #30b866;
    }

    .text-red {
      color: #ee333e;
    }

    .item {
      font-weight: 500;
      padding: 2rem 1rem;
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .itemName {
      color: #616161;
      font-size: 18px;
      margin-bottom: 0.5rem;
    }
    .itemValue {
      font-size: 15px;
      color: #939393;
    }
  }

  .results {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 30px;
    font-size: 27px;
    color: #252525;
    text-align: center;

    .title {
      margin-bottom: 15px;
      color: #7a7a7a;
    }

    .message {
      font-weight: 500;
    }
  }
}
.ReportTitle {
  color: white;
  font-size: 25px;
}
.imeiTable {
  text-align: center;
}
.imeiTableDescription {
  font-weight: 400;
  background-color: #f7f7f7;
  cursor: pointer;
}
.imeiTableMainTitle {
  color: #ee333e;
  font-size: 20px;
  font-weight: 500;
}
.imeiTableTitleInImei {
  color: #ee333e;
  font-size: 20px;
}
.imeiRowNumber {
  color: #ee333e;
}
