.OpenInvoices {
  .tableDescription {
    font-weight: 600;
  }

  .currencyHeader {
    color: #c01717;
    background-color: #f7f7f7;
  }

  .over7DayInvoices {
    color: green;
    cursor: pointer;
  }

  .less7DayInvoices {
    color: orange;
    cursor: pointer;
  }

  .expiredInvoices {
    color: red;
    cursor: pointer;
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    background-color: #fff;
  }

  .table-hover tbody tr td,
  .table-hover tbody tr th {
    border-bottom: 1px dotted #d8d3d3;
  }

  .componyInfoContainer {
    border-top: 1px dotted #d8d3d3;
    padding: 15px 0px;

    .firstRow {
      display: flex;
      justify-content: space-between;

      .Part {
        display: flex;

        .Title {
          width: 150px;
          display: flex;
          justify-content: space-between;
        }
      }

      .secondPart {
        display: flex;
        width: 35%;

        .Title {
          width: 150px;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .componyInfoContainer .firstRow .Part .Title,
    .componyInfoContainer .firstRow .secondPart,
    .componyInfoContainer .firstRow .secondPart .Title {
      width: auto;
    }

    .buttonContainer {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    table.table tr th,
    table.table tr td {
      white-space: nowrap;
    }
  }
}

table.table tr th,
table.table tr td {
  border-color: #e9e9e9;
  font-size: 14px;
}