.files {
  display: block;
  font-size: 16px;
  margin: 1rem 0;

  span:last-of-type {
    cursor: pointer;
  }
}
.file {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  input + label {
    font-size: 15px;
    font-weight: 500;
    color: white !important;
    background-color: #ec4c4c;
    display: block;
    width: max-content;
    padding: 0.5rem 1rem 0.3rem;
    margin: 0;
    cursor: pointer;
  }

  input:focus + label,
  input + label:hover {
    background-color: darken(#ec4c4c, 5);
  }

  input:focus + label {
    outline: 1px solid #ec4c4c4d;
    outline: -webkit-focus-ring-color #ec4c4c4d 5px;
  }
}
