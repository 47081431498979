$myOrderColor: #616161;
$myOrderBorderColor: #e0e0e2;
$productColorPrimary: #ee333e;

.myOrder {
  // .box {
  // 	padding: 30px;
  // 	background-color: #fff;
  // 	border: 1px solid #f0f0f1;
  // }

  .sidebar {
    max-width: 309px;
    min-height: 456px;
    margin-bottom: 1rem;

    .userName {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 500;
    }

    .title {
      position: relative;
      top: 3px;
      color: #ee3439;
      font-size: 18px;
      font-weight: 500;
    }

    .links {
      margin-left: 41px;
      a {
        display: block;
        color: #616161;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 1rem;
      }
    }
  }

  .sidePhotos {
    display: block;

    img {
      margin-bottom: 1rem;
    }
  }

  .search {
    position: relative;
    .icon {
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    input {
      display: block;
      max-width: 285px;
      margin-left: auto;
      background-color: #f0f0f1;
      font-size: 17px;
      padding-right: 40px;

      &::placeholder {
        position: relative;
        top: 2px;
        font-size: 18px;
        color: #ababaf;
      }
    }
  }

  .orderTitle {
    position: relative;
    color: $myOrderColor;
    font-size: 18px !important;
    font-weight: 500;
    padding-bottom: 1rem;
    padding-left: 1.3rem;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 35px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background-color: #ec4c4c;
      width: 122px;
      height: 3px;
      display: block;
    }
  }

  form {
    .formElements {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
    }

    .formEl {
      max-width: 285px;
      width: 100%;

      label {
        display: block;
        font-size: 18px;
        font-weight: 500;
        color: #616161;
        margin-bottom: 6px;
      }
      input:not([type='checkbox']),
      select {
        border: 1px solid #e0e0e2;
        border-radius: 0;
        width: 100% !important;
        height: 43px !important;
      }
    }
  }

  .myOrderActions {
    display: flex;
    gap: 1rem;
    margin: 5rem auto;
    max-width: 624px;
    width: 100%;

    @media (max-width: 790px) {
      flex-wrap: wrap;
    }
  }

  .button {
    background-color: #fff;
    color: #ec4c4c;
    font-size: 18px;
    border: 1px solid #ee333e;

    &:hover {
      background-color: #ee333e;
      color: #fff;
    }
  }

  .buttonExcel {
    background-color: #fff;
    color: #1f9733;
    font-size: 18px;
    border: 1px solid #1f9733;

    &:hover {
      background-color: #1f9733;
      color: #fff;
    }
  }

  .marketPlace {
    margin-top: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background: #fff;
  }

  .marketPlace .row {
    display: flex;
    flex-wrap: wrap;
  }

  .marketPlace .col-lg,
  .marketPlace .col-sm-12 {
    flex: 1;
    margin-bottom: 10px;
  }

  .marketPlace .item {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .marketPlace .itemName {
    font-weight: 500;
    margin-right: 5px;
    font-size: 15px;
    white-space: nowrap;
  }

  .marketPlace .itemValue {
    color: #555;
    max-width: 180px;
  }

  .marketPlace .clickable {
    color: #007bff;
    cursor: pointer;
  }
  .itemValue.clickable {
    cursor: pointer;
    color: #007bff;
    text-decoration: underline; /* Underline the text */
    transition: color 0.2s, text-decoration 0.2s; /* Smooth transition for hover effect */
  }

  .itemValue.clickable:hover {
    color: #0056b3; /* Change color on hover */
    text-decoration: none; /* Optionally remove underline on hover */
  }

  .marketPlace .text-green {
    color: lime;
  }

  .marketPlace img.sellerImage {
    max-width: 50px;
    height: auto;
  }

  .marketPlace .icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .marketPlace .icon-container img {
    max-width: 30px;
    height: auto;
  }

  .results {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    padding: 30px;
    font-size: 27px;
    color: #252525;
    text-align: center;

    .title {
      margin-bottom: 15px;
      color: #7a7a7a;
    }

    .message {
      font-weight: 500;
    }
  }
}

.print-button {
  background-color: #8c8c8d;
  border-radius: 10px;
  color: white !important;
  border: none;
  width: 180px;
  height: 50px;
  text-decoration: none;
  padding: 5px !important;
  font-size: 18px;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color 0.3s ease; /* geçiş efekti */
}

.print-button:hover {
  background-color: #7a7a7c; /* hover efekti */
}

.excel-button {
  background-color: transparent;
  border-radius: 10px;
  color: #053805 !important;
  border: none;
  width: 180px;
  height: 50px;
  text-decoration: none;
  padding: 5px !important;
  white-space: nowrap;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition effect for hover */
  font-weight: 500;
}

.excel-button:hover {
  background-color: #e6e6e6; /* Background color on hover */
}

.print-button {
  background-color: transparent;
  border-radius: 10px;
  color: #242524 !important;
  border: none;
  width: 140px;
  height: 50px;
  text-decoration: none;
  padding: 5px !important;
  white-space: nowrap;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transition effect for hover */
  font-weight: 500;
}

.print-button:hover {
  background-color: #e6e6e6; /* Background color on hover */
}

.modal-body-cargo {
  padding: 15px;
}

.info-row-cargo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  white-space: nowrap;
}

.info-row-cargo b {
  width: 150px;
  flex-shrink: 0;
}

.info-row-cargo .label-cargo {
  font-weight: 600;
  color: #333333;
}

.info-row-cargo .colon-cargo {
  margin-right: 10px;
}

.info-row-cargo .value-cargo {
  flex-grow: 1;
  word-break: break-word;
  //color: #666666;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .info-row-cargo {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .info-row-cargo b,
  .info-row-cargo .value-cargo {
    width: 100% !important;
  }

  .info-row-cargo .colon-cargo {
    display: none;
  }
}

.itemValueText {
  word-wrap: break-word;
}
