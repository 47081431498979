.list {
  font-size: 19px;
}

.form-baslik {
  color: rgb(46, 46, 46);
  font-size: 27px;
  font-weight: bold;
  width: 100%;
  padding: 5px 10px 0 15px;
  text-align: center;
}

.etkin {
  display: block;
  text-align: right;
  font-size: 16px;
}

.formbanner {
  margin-left: 10px;
}

.bannerekleblock {
  margin-top: 33px;
}

.bannerekle {
  color: #fff;
  font-size: 16px;
}

.bannerekle:hover {
  font-size: 17px;
}

.labeltext {
  color: #777777 !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
