.login-fg .form-container {
  color: #ccc;
  position: relative;
  padding: 5% 0;
}

.login-fg .login {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-fg #userName-error,
.login-fg #password-error {
  display: none !important;
}

.login-fg .right_fixed_texts {
  position: absolute;
  width: 100%;
  max-width: 100%;
  bottom: 3%;
}

.login-fg .footer-bottom-iconText a {
  color: #808080;
  font-weight: 400;
  font-size: 1.1rem;
}

.login-fg .footer-bottom-iconText a i {
  opacity: 0.8;
  font-size: 1.2rem;
}

.login-fg .right_fixed_texts span {
  font-size: 1.3rem;
}

.login-fg .footer-bottom-title {
  color: #808080;
  font-size: 1.5rem;
}

.login-fg .footer-bottom-text {
  color: #808080;
  font-size: 0.9rem;
  text-align: left;
}

.login-fg a em {
  color: #808080;
  opacity: 0.8;
}

.login-fg .login-section {
  max-width: 100%;
  overflow-y: auto;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 80px;
  text-align: center;
  width: 100%;
}

.login-fg .form-fg {
  width: 100%;
  text-align: center;
}

.login-fg .form-container .form-group {
  width: 30%;
  margin: 0 auto 3% auto;
}

.login-fg .form-container .form-fg,
.login-fg .form-container .form-control {
  float: left;
  width: 100%;
  border-radius: 0 !important;
  position: relative;
}

.login-fg .form-container .form-control {
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #adadad;
}

.login-fg .form-container .form-control::placeholder {
  font-size: 1.1rem;
  font-weight: 400;
  color: #adadad;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745 !important;
}

.login-fg .form-container .form-fg input {
  float: left;
  width: 100%;
  padding: 11px 40px;
  font-size: 1rem;
}

.login-fg .form-container .form-fg i:not(.input-group-append i) {
  position: absolute;
  top: 8px;
  z-index: 4;
  left: 2%;
  font-size: 1.8rem;
  color: #adadad;
}

.form-control:focus,
.form-check-input:focus,
.form-control.is-valid:focus,
.login-fg .form-container .btn-fg.focus,
.btn-fg:focus {
  box-shadow: none;
}

.login-fg .bg {
  background: rgba(0, 0, 0, 0.04) repeat;
  background-size: cover;
  top: 0;
  width: 100%;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.login-fg .info h3 {
  font-size: 2.2rem;
  color: #fff;
  font-family: 'Nexa';
  font-weight: 500;
  margin-bottom: 3%;
}

.login-fg .info h2 {
  font-size: 4rem;
  color: white;
  font-weight: 400;
  line-height: 50px;
}

.login-fg .info h2 span {
  font-size: 4rem;
  color: white;
  font-weight: 700;
}

.login-fg .info a {
  display: block;
  line-height: 35px;
}

.login-fg .info p {
  font-size: 1.3rem;
}

.login-fg .info .background-white {
  padding: 5%;
  background-color: rgba(255, 255, 255, 0.45) !important;
  border-radius: 28px;
}

.login-fg .form-container .btn-md {
  cursor: pointer;
  padding: 3%;
  height: 45px;
  letter-spacing: 1px;
  font-size: 1.3rem;
  font-weight: 400;
  color: #fff;
}

.login-fg .form-container p,
.login-fg .form-container p a {
  margin: 0;
  color: #616161;
}

.login-fg .form-container button:focus {
  outline: none;
  outline: 0 auto -webkit-focus-ring-color;
}

.login-fg .form-container .btn-fg {
  background: #d21649;
  border: 1.28409px solid #ffffff;
  border-radius: 8px;
  color: #fff;
}

.login-fg .form-container .btn-fg:hover {
  background: #fff;
  color: #6d6d6d;
  border-color: #d21649;
}

.login-fg .form-container .checkbox {
  margin-bottom: 5%;
  font-size: 1.2rem;
}

.login-fg .form-container .form-check {
  float: left;
  margin-bottom: 0;
}

.login-fg .form-container .form-check a {
  color: #d6d6d6;
  float: right;
}

.login-fg .form-container .form-check-input {
  position: absolute;
  margin-left: 0;
  margin-top: 0;
}

.login-fg .form-check-input:focus {
  border-color: #ccc;
}

.login-fg .form-container .form-check-label {
  padding-left: 25px;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #6d6d6d;
  font-weight: 400;
}

.login-fg .form-container .checkbox a {
  font-size: 1.2rem;
  color: #6d6d6d;
  float: right;
  margin-left: 3px;
}

.login-fg .form-check-input:checked {
  background-color: #d21649;
  border-color: #d21649;
}

.login-fg .login-section h2 {
  font-size: 2.3rem;
  color: #252525;
  font-weight: 600;
}

.login-fg .login-section h2 span {
  color: #d21649;
}

.login-fg .login-section h3 {
  font-size: 1.15rem;
  color: #a4a4a4;
  font-weight: 400;
}

.login-section .logo {
  margin-top: 5%;
}

.company-logos {
  position: initial;
  bottom: 0;
  background-color: white;
}

.company-logos a {
  border: none !important;
  display: flex;
  align-items: center;
  border-radius: 50px !important;
  padding: 1% !important;
  height: 100%;
}

.info .box-transform {
  margin-top: 100px;
}

.swiper-button-next,
.swiper-button-prev {
  color: grey !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: inherit;
}

.swiper-slide img:not(.productButton img, .productInfoBox img, .swiper .brandItem img, .indicator img, .action img) {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.error {
  font-size: 12px;
  color: red;
  padding-top: 6px;
}

@media (max-width: 1024px) {
  .login-fg .bg {
    display: none;
  }
}

.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  height: 100vh;
  font-size: 2rem;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.errLogo {
  width: 300px;
}