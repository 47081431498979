.productCardGrid1 {
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

  .productCard {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .productCheckbox {
      position: absolute;
      top: 80px;
      left: 10px;
      z-index: 1;
    }

    .actions {
      right: 5px;
      top: 80px;
      z-index: 1;
    }

    .productImage {
      display: grid;
      place-items: center;
      width: 200px;
      height: 200px;
      margin-inline: auto;
      margin-bottom: 1rem;
      overflow: hidden;
      z-index: 0;

      img {
        display: block;
        max-height: 100%;
      }
    }

    .productButton {
      position: relative;
      top: -2rem;
      width: 157px;
      margin-inline: auto;
    }
  }

  .productInfo2 {
    min-width: 200px;
    width: 100%;
    padding-inline: 1rem;
    margin-inline: auto;
    margin-bottom: 1rem;

    .productName {
      font-size: 16px;
    }

    .productCode,
    .productModel {
      color: #616161;
      font-size: 13px;
    }
  }

  .productInfoBox {
    position: relative;
    background-color: #fff;
    box-shadow: 0px 4px 32px -2px rgba(0, 0, 0, 0.15);
    font-size: 13px;
    font-weight: 500;
    padding: 2rem 8px 3rem;
    align-self: flex-end;
    width: 100%;
    margin: auto auto 0 auto;

    & > div {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .productBrand {
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      width: 50px;
      padding-inline: 0px;
      background: #fff;
      border: 1px solid #e0e0e2;

      display: grid;
      place-items: center;

      img {
        display: block;
        height: 45px;
        width: 45px;
      }
    }

    .price {
      float: right;
    }

    .price-none-decoration {
      text-decoration: none;
    }

    .priceTitle {
      color: #616161;
    }

    .priceTitle--gray {
      color: #ababaf;
    }

    .stock {
      color: #ababaf;
    }

    .stockNumber {
      width: 40px;
      height: 28px;
      min-height: 28px;
      padding: 0;
      padding-top: 5px;
      text-align: center;
    }
  }
}

.morePadProduct .productCard {
  padding: 30px !important;
  box-shadow: none;
  border: 1px solid #dfdfdf;
}

.tableProductName li {
  list-style: none;
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
  line-height: 19px;
  color: #636363;
  opacity: 0.99;
}
