.creditStatement {
  .topBar {
    display: flex;
    flex-wrap: wrap;
    min-height: 40px;
    font-weight: 600;
  }
  .boxTitle {
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #ececec;
    width: 200px;
    flex-shrink: 0;
    @media (max-width: 1000px) {
      width: 100%;
      padding: 0.5rem 1rem;
    }
  }
  .creditLimits {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    background-color: #f8f8f8;
    width: calc(100% - 200px);

    @media (max-width: 1000px) {
      width: 100%;
      justify-content: flex-start;
      padding: 1rem;
    }
  }
  .titleBorderless {
    display: flex;
    margin: 1.2rem 0;
    font-weight: 600;
    font-size: 18px;
  }

  h1 {
    font-size: clamp(1rem, 2vw, 2rem);
    color: #ec4c4c;
  }

  p {
    font-size: 16px;
  }

  .files {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem 1rem;

    .file {
      display: flex;
      align-items: center;
      padding: 0.4rem 0;
      border: 1px solid transparent;
      font-size: 14px;
      font-weight: 500;
      color: inherit;
      text-align: left;
      transition: color, border, 0.3s ease-out;
      font-size: 15px;

      &:hover {
        color: #ec4c4c;
        border: 1px solid #ccc;
      }
    }
  }
}
