.form-check {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-left: 0 !important;
}

.form-check-input {
  position: relative;
  margin: 0 !important;
  float: unset !important;
  width: 20px;
  height: 20px;
  outline: none;
  cursor: pointer;
  flex-shrink: 0;

  &:checked {
    background-color: #d21649;
    border-color: #d21649;
  }
}

.form-check-label {
  height: 100%;
  margin: 0;
  padding-left: 0.5rem;
  padding-top: 2px;
  font-size: 15px;
  cursor: pointer;
}

.white-circle label {
  padding-top: 9px;
}

.white-circle-input.form-check-input:checked[type='radio']+label {
  background-color: rgba(238, 51, 62, 0.1);
  border-radius: 40px;
  padding-top: 8px;
}